import React, { useState, useContext, useEffect } from "react";
import Layout from "./components/Layout"
import { AuthContext } from "./service/auth";
import { auth, db } from "./service/firebase";
import { signOut, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { collection, getDoc, doc, setDoc, updateDoc } from "firebase/firestore";

import heroBackground from "./assets/hero-bg.jpg";

const Profile = () => {
    const { currentUser } =  useContext(AuthContext);
    const navigate = useNavigate();
    const [userdata, setUserdata] = useState({});

    const [userhasnormalaccount, setUserhasnormalaccount] = useState(false);

    const [userphone, setUserphone] = useState("");
    const [userbloodgroup, setUserBloodgroup] = useState("willnotshare");
    const [userlocation, setUserlocation] = useState("notselected");
    const [userhomelocation, setUserhomelocation] = useState("notselected");
    const [userfacebooklink, setUserfacebooklink] = useState("");
    const [formbuttontext, setFormbuttontext] = useState("UPDATE PROFILE");
    const [formresetbuttontext, setFormResetButtontext] = useState("RESET MY PASSWORD");

    useEffect(() => {
        if (currentUser) {
            const userDocRef = doc(db, "users", currentUser.uid);
            getDoc(userDocRef)
            .then((snap) => {
                if (snap.exists()){
                    setUserdata(snap.data());
                    setUserphone(snap.data().phone);
                    setUserBloodgroup(snap.data().bloodgroup);
                    setUserlocation(snap.data().location);
                    setUserfacebooklink(snap.data().facebooklink);
                    setUserhomelocation(snap.data().homelocation);
                }
            });

            if(currentUser.providerData[0].providerId === "password"){
                setUserhasnormalaccount(true);
            } else {
                setUserhasnormalaccount(false);
            }
        }

    }, [currentUser]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormbuttontext("PLEASE WAIT...");
        const userDocRef = doc(db, "users", currentUser.uid);
        updateDoc(userDocRef, {
            phone: userphone,
            location: userlocation,
            homelocation: userhomelocation,
            facebooklink: userfacebooklink,
            bloodgroup: userbloodgroup
        }).then(() => {
            setFormbuttontext("UPDATED!");
            setTimeout(() => {
                setFormbuttontext("UPDATE PROFILE");
            }, 5000);
        }).catch((error) => {
            setFormbuttontext("UPDATE PROFILE");
        });
    }


    const handlePasswordReset = (e) => {
        e.preventDefault();
        function onReset() {
            setFormResetButtontext("PLEASE WAIT...");
            sendPasswordResetEmail(auth, currentUser.email)
            .then(function() {
              setFormResetButtontext("RESET DONE, CHECK EMAIL!");
              setTimeout(() => {
                signOut(auth);
                navigate("/login/", {state : {returnurl: "/"}});
              }, 2000);
            })
            .catch(function(error) {
              setFormResetButtontext("RESET MY PASSWORD");
            });
        }
        onReset();
    };

    function renderDistricts(){
        return(
            <>
            <option value="notselected">Select District</option>
            <option value="Bagerhat">Bagerhat</option>
            <option value="Bandarban">Bandarban</option>
            <option value="Barguna">Barguna</option>
            <option value="Barisal">Barisal</option>
            <option value="Bhola">Bhola</option>
            <option value="Bogra">Bogra</option>
            <option value="Brahmanbaria">Brahmanbaria</option>
            <option value="Chandpur">Chandpur</option>
            <option value="Chittagong">Chittagong</option>
            <option value="Chuadanga">Chuadanga</option>
            <option value="Comilla">Comilla</option>
            <option value="Cox'sBazar">Cox'sBazar</option>
            <option value="Dhaka">Dhaka</option>
            <option value="Dinajpur">Dinajpur</option>
            <option value="Faridpur">Faridpur</option>
            <option value="Feni">Feni</option>
            <option value="Gaibandha">Gaibandha</option>
            <option value="Gazipur">Gazipur</option>
            <option value="Gopalganj">Gopalganj</option>
            <option value="Habiganj">Habiganj</option>
            <option value="Jaipurhat">Jaipurhat</option>
            <option value="Jamalpur">Jamalpur</option>
            <option value="Jessore">Jessore</option>
            <option value="Jhalokati">Jhalokati</option>
            <option value="Jhenaidah">Jhenaidah</option>
            <option value="Khagrachari">Khagrachari</option>
            <option value="Khulna">Khulna</option>
            <option value="Kishoreganj">Kishoreganj</option>
            <option value="Kurigram">Kurigram</option>
            <option value="Kushtia">Kushtia</option>
            <option value="Lakshmipur">Lakshmipur</option>
            <option value="Lalmonirhat">Lalmonirhat</option>
            <option value="Madaripur">Madaripur</option>
            <option value="Magura">Magura</option>
            <option value="Manikganj">Manikganj</option>
            <option value="Maulvibazar">Maulvibazar</option>
            <option value="Meherpur">Meherpur</option>
            <option value="Munshiganj">Munshiganj</option>
            <option value="Mymensingh">Mymensingh</option>
            <option value="Naogaon">Naogaon</option>
            <option value="Narail">Narail</option>
            <option value="Narayanganj">Narayanganj</option>
            <option value="Narsingdi">Narsingdi</option>
            <option value="Natore">Natore</option>
            <option value="Nawabganj">Nawabganj</option>
            <option value="Netrokona">Netrokona</option>
            <option value="Nilphamari">Nilphamari</option>
            <option value="Noakhali">Noakhali</option>
            <option value="Pabna">Pabna</option>
            <option value="Panchagarh">Panchagarh</option>
            <option value="Patuakhali">Patuakhali</option>
            <option value="Pirojpur">Pirojpur</option>
            <option value="Rajbari">Rajbari</option>
            <option value="Rajshahi">Rajshahi</option>
            <option value="Rangamati">Rangamati</option>
            <option value="Rangpur">Rangpur</option>
            <option value="Satkhira">Satkhira</option>
            <option value="Shariatpur">Shariatpur</option>
            <option value="Sherpur">Sherpur</option>
            <option value="Sirajganj">Sirajganj</option>
            <option value="Sunamganj">Sunamganj</option>
            <option value="Sylhet">Sylhet</option>
            <option value="Tangail">Tangail</option>
            <option value="Thakurgaon">Thakurgaon</option>
            </>
        );
    };

    const inputclass = "block w-full px-6 py-4 mb-6 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none";
    const formlabelclass = "text-xs font-bold mb-2 block uppercase text-gray-700";

    return (
        <Layout title="Update Profile">
            {currentUser &&
            <div className="block bg-white rounded-xl shadow-lg overflow-hidden">
                
                <div className="w-full bg-cover bg-center" style={{ backgroundImage: `url(${heroBackground})` }}>
                    <div className="px-4 py-24 w-full h-full bg-sky-500 rounded-t-xl bg-opacity-10 items-center text-center justify-center flex ">
                        <h1 className="text-sky-500 font-bold text-3xl tracking-widest">UPDATE YOUR PROFILE</h1>
                    </div>
                </div>
                <div className="w-full px-6 py-8 md:px-12 md:py-16">
                <form className="eventCreateFrom flex flex-wrap" onSubmit={handleSubmit}>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Your Name</label>
                            <input disabled type="text" name="name" className={inputclass} placeholder="Name" defaultValue={currentUser.displayName}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Your Email</label>
                            <input disabled type="text" name="email" className={inputclass} placeholder="Email" defaultValue={currentUser.email}/>
                        </div>

                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Phone Number</label>
                            <input required type="text" name="phone" className={inputclass} placeholder="Phone" value={userphone} onChange={(e) => setUserphone(e.target.value)}/>
                        </div>

                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Facebook Link</label>
                            <input required type="text" name="facebooklink" className={inputclass} placeholder="Facebook Link" value={userfacebooklink} onChange={(e) => setUserfacebooklink(e.target.value)}/>
                        </div>

                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Your Blood Group</label>
                            <select name="bloodgroup" className={inputclass} value={userbloodgroup} onChange={(e) => setUserBloodgroup(e.target.value)}>
                                <option value="willnotshare">I don't want to share</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                            </select>
                        </div>

                        <div className="w-full md:w-3/6 px-3"></div>

                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Your Current Location</label>
                            <select name="location" className={inputclass} value={userlocation} onChange={(e) => setUserlocation(e.target.value)}>
                                {renderDistricts()}
                            </select>
                        </div>

                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Your Home Location</label>
                            <select name="homelocation" className={inputclass} value={userhomelocation} onChange={(e) => setUserhomelocation(e.target.value)}>
                                {renderDistricts()}
                            </select>
                        </div>
                        
                        <div className="w-full px-3">
                            <button type="submit" className="bg-sky-500 hover:bg-sky-600 transition p-4 block w-full rounded-lg text-white font-bold">{formbuttontext}</button>
                        </div>
                    </form>
                    {userhasnormalaccount &&
                        <div className="w-full px-3">
                            <div className="flex items-center justify-center border-t-[1px] border-t-slate-300 w-full relative mt-12">
                                <div className="-mt-1 font-bod bg-white px-5 absolute font-bold text-xs">WANT TO CHANGE YOUR PASSWORD?</div>
                            </div>
                            <button type="submit" className="bg-green-400 hover:bg-green-500 transition p-4 block w-full rounded-lg text-white font-bold mt-6" onClick={handlePasswordReset}>{formresetbuttontext}</button>
                        </div>
                    }
                    
                </div>
            </div>
            }
        </Layout>
        
    );
}

export default Profile;