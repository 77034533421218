import React, { useState, useContext, useEffect } from "react";
import { auth, db } from "../service/firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "../service/auth";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import { collection, getDoc, doc, setDoc } from "firebase/firestore";

import { Menu } from '@headlessui/react'


import ppclogo from "../assets/ppc.png"
import ppcicon from "../assets/ppc-icon.png"
import ppciconwhite from "../assets/ppc-icon-white.png"
import sppayments from "../assets/sp-payments.png"




const Layout = ({children, title, isnaked}) => {
    
    useEffect(() => {
        if(title){
            document.title = title
        }
        
    }, [title]);

    const location = useLocation();

    const { currentUser } =  useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [splashscreen, setSplashscreen] = useState(true);
    const [useradmin, setUseradmin] = useState(false);
    const [userloggedin, setUserloggedin] = useState(false);
    const [userdataloaded, setUserdataloaded] = useState(false);
    const [userverified, setUserverified] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(location.pathname === "/" || location.pathname === "/about/" || location.pathname === "/contact/" || location.pathname === "/events/" || location.pathname.split("/")[1] === "event" || location.pathname === "/login/" || location.pathname === "/password-reset/" || location.pathname === "/signup/" || location.pathname === "/privacy-policy/" || location.pathname === "/refund-policy/" || location.pathname === "/terms/"){
                setSplashscreen(false);
                if(user){
                    setUsername(user.displayName);
                }
                
            } else {
                if (user) {
                    setUsername(user.displayName);
                    setUserloggedin(true);
                    setSplashscreen(true);
                } else {
                    navigate("/login/", {state : {returnurl: location.pathname}});
                }
            }
        });
    }, [currentUser]);

    

    const clickLogin = () => {
        if (currentUser) {
            signOut(auth);
            navigate("/login/", {state : {returnurl: "/"}});
        } else {
            navigate("/login/", {state : {returnurl: "/"}});
        }
    };
    const menuCLass = "navigationmenuitem font-bold text-sm text-gray-900 mx-3 hover:text-sky-500 transition";
    const popoverMenuCLass = "block w-full px-4 py-3 text-sm hover:bg-gray-100 outline-none text-center border-b font-bold";
    var md5 = require("md5");
    let gravatarurl = "https://www.gravatar.com/avatar/00000000000000000000000000000000?s=50";
    if (currentUser) {
        gravatarurl = "https://www.gravatar.com/avatar/"+md5(currentUser.email)+"?s=50";
    }

    useEffect(() => {
        if (currentUser) {
            const admindocRef = doc(db, "admins", currentUser.uid);

            getDoc(admindocRef)
            .then((snap) => {
                if (snap.exists()){
                    if(snap.data().role === "admin"){
                        setUseradmin(true);
                    }
                }; 
            });


            const userDocRef = doc(db, "users", currentUser.uid);
            getDoc(userDocRef)
            .then((snap) => {
                if (snap.exists()){
                    setUserdataloaded(true);
                    setSplashscreen(false);
                    if(snap.data().verified === true){
                        setUserverified(true);
                    } else {
                        if(location.pathname === "/book-now/"){
                            //navigate("/");
                        }
                    }
                } else {
                    const newUserData = doc(collection(db, "users"), currentUser.uid);
                    var data = {
                        verified: false
                    };
                    setDoc(newUserData, data);
                    setSplashscreen(false);
                }
            });
        }

    }, [currentUser]);

    

    
  return (
    <>  
        
        <div className="global-wrapper bg-gray-50 relative max-w-screen relative">
            {splashscreen &&
                <div className="fixed z-900 bg-sky-500 w-screen h-screen flex flex-wrap items-center justify-center">
                    <img className="w-22 animate-bounce" src={ppciconwhite} alt="Premier Club"/>
                </div>
            }
            <div className={`relative bg-white sticky top-0 shadow z-800 ${isnaked ? "hidden" : ""}`}>
                <div className="max-w-5xl mx-auto px-4">
                    <div className="flex justify-between items-center py-4 md:justify-start space-x-5 lg:space-x-10">
                        <div className="flex justify-start relative">
                            <Link to="/"><img className="w-32" src={ppclogo} alt="Premier Club"/></Link>
                        </div>
                        
                        {currentUser ?
                            <nav className="hidden md:flex justify-center flex-1">
                                <NavLink to="/" className={menuCLass}>DASHBOARD</NavLink>
                                <NavLink to="/events/" className={menuCLass}>EVENTS</NavLink>
                                <NavLink to="/tickets/" className={menuCLass}>YOUR TICKETS</NavLink>
                            </nav>
                        :
                            <nav className="hidden md:flex justify-center flex-1">
                                <NavLink to="/" className={menuCLass}>HOME</NavLink>
                                <NavLink to="/about/" className={menuCLass}>ABOUT US</NavLink>
                                <NavLink to="/events/" className={menuCLass}>OUR EVENTS</NavLink>
                                <NavLink to="/contact/" className={menuCLass}>CONTACT</NavLink>
                            </nav>
                        } 
                        
                        <div className="flex items-center justify-end md:flex-1 lg:w-0">
                            {currentUser ? 
                                <div className="relative flex items-center">
                                    <Menu>
                                        <Menu.Button className="outline-none">
                                            <div className="flex items-center justify-end self-center">
                                                <div className="hidden md:block text-right uppercase text-gray-800"><p className="text-xs leading-4 tracking-widest">Hi, There!</p><h3 className="font-bold text-sm md:text-md leading-4">{username}</h3></div><div><img src={gravatarurl} alt={username} className="w-10 border ml-2 rounded-full"/></div>
                                            </div>
                                        </Menu.Button>
                                        <Menu.Items className="absolute z-40 right-0 top-20 outline-none">
                                            <div className="bg-white rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden w-48">
                                                {useradmin &&
                                                    <div className="bg-sky-50">
                                                        <Link to="/create-event/" className={popoverMenuCLass}>CREATE EVENT</Link>
                                                        <Link to="/participants/" className={popoverMenuCLass}>SEE PARTICIPANTS</Link>
                                                        <Link to="/scan-ticket/" className={popoverMenuCLass}>SCAN TICKETS</Link>
                                                    </div>
                                                }
                                                <Link to="/events/" className={popoverMenuCLass}>VIEW EVENTS</Link>
                                                <Link to="/tickets/" className={popoverMenuCLass}>YOUR TICKETS</Link>
                                                <Link to="/update-profile/" className={popoverMenuCLass}>UPDATE PROFILE</Link>
                                                <button onClick={clickLogin} className={`bg-red-500 hover:bg-red-600 border-none text-white ${popoverMenuCLass}`}>LOGOUT</button>
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                            :   <div>
                                    <button onClick={clickLogin} className="border-2 border-sky-500 text-sky-500 rounded-full font-bold px-8 py-2 hover:bg-sky-500 hover:text-white transition">LOGIN</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {userdataloaded && userverified === false &&
                <div className="max-w-5xl mx-auto px-4 pt-12 hidden">
                    <div className="bg-white shadow-xl rounded-xl flex flex-wrap w-full px-8 py-6 items-center">
                        <div className="grow uppercase text-red-400 font-bold text-xl">You are not a verified member</div>
                        <div className="justify-self-end">
                            <form method="POST" action="https://alohomora.premierprofessionals.club/verify.php">
                                <input type="hidden" name="userid" defaultValue={currentUser.uid}/>
                                <input type="hidden" name="username" defaultValue={username}/>
                                <input type="submit" className="bg-sky-500 px-6 py-3 font-bold text-white hover:bg-sky-600 rounded-lg cursor-pointer" value="PLEASE VERIFY YOURSELF"/>
                            </form>
                        </div>
                    </div>
                </div>
            }

            <div className="max-w-5xl mx-auto px-4 py-10">
                {children}
            </div>
        </div>
        <div className={`footer bg-white border-t-2 ${isnaked ? "hidden" : ""}`}>
            <div className="max-w-5xl mx-auto px-4 sm:px-10 py-10">
                <div className="mb-6 text-center block">
                    <a href="https://www.facebook.com/groups/premierprofessionalsclub" className="inline-block"><img className="w-16" src={ppcicon} alt="Premier Club"/></a>
                </div>
                <div className="text-center font-bold text-sm">&copy; PREMIER PROFESSIONALS CLUB</div>
                <div className="text-center mt-4 text-xs">
                    <Link to="/privacy-policy/" className="mx-2 hover:text-sky-500">PRIVACY POLICY</Link>
                    <Link to="/refund-policy/" className="mx-2 hover:text-sky-500">REFUND POLICY</Link>
                    <Link to="/terms/" className="mx-2 hover:text-sky-500">TERMS & CONDITIONS</Link>
                    <a href="https://www.facebook.com/premierprofessionalsclub" className="mx-2 hover:text-sky-500" target="_blank">FACEBOOK</a>
                </div>
                <div className="text-center mt-6">
                    <img className="w-4/6 inline-block" src={sppayments} alt="Premier Club Payments"/>
                </div>
                <div className="text-xxs text-center text-gray-500 mt-8">MANAGED BY <strong><a href="https://whitedigit.com" target="_blank">WHITE DIGIT TECHNOLOGIES</a></strong></div>
            </div>
        </div>
    </>
  )
}

export default Layout