import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from "firebase/performance";

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);

if(process.env.REACT_APP_APP_CHECK_ID){
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_APP_CHECK_ID),

        isTokenAutoRefreshEnabled: true
    });
}

const perf = getPerformance(app);


export default app;