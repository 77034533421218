import React from "react";
import Layout from "./components/Layout"

const MessagePage = ({type}) => {
    return (
        <>
            {type === "paymentsuccess" &&
                <Layout title="Payment Successful">
                    <div className="text-center py-32 w-full block">
                        <h1 className="font-black text-sky-400 text-2xl md:text-6xl mb-4 uppercase">Congratulations!</h1>
                        <p className="font-bold md:tracking-widest text-sm md:text-xl text-sky-500">PLEASE VISIT TICKETS PAGE TO SEE YOUR TICKET.</p>
                    </div>
                </Layout>
            }
            {type === "paymentfailed" &&
                <Layout title="Payment Failed">
                    <div className="text-center py-32 w-full block">
                        <h1 className="font-black text-red-400 text-2xl md:text-6xl mb-4 uppercase">PAYMENT FAILED</h1>
                        <p className="font-bold md:tracking-widest text-sm md:text-xl text-red-500 mb-6">SOMETHING WENT WRONG WITH YOUR PAYMENT.</p>
                        <p className="">If you're charged, please communicate with one of our admins.</p>
                    </div>
                </Layout>
            }
        </>
        
    );
}

export default MessagePage;