import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Layout from "./components/Layout"
import { toJpeg} from 'html-to-image';

import ppclogo from "./assets/ppc.png"

function DownloadTicket() {
    const location = useLocation();
    const navigate = useNavigate();
    const ticketdata = location.state;

    const [downloadMessage, setDownloadMessage] = useState("DOWNLOAD YOUR TICKET!");

    var eventdate = new Date(ticketdata.eventdate);
    const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });

    function downloadTicketPDF(){
        setDownloadMessage("GENERATING YOUR TICKET...");
        setTimeout(() => {
            toJpeg(document.getElementById('ticketel'), {backgroundColor: "#fff", width: "592", height: "824"})
            .then(function (dataUrl) {
                setDownloadMessage("DOWNLOADING YOUR TICKET...");
                const link = document.createElement('a')
                link.download = ticketdata.eventname + ".jpg";
                link.href = dataUrl
                link.click();
                setTimeout(() => {
                    setDownloadMessage("DOWNLOAD DONE!");
                }, 2000);
                setTimeout(() => {
                    setDownloadMessage("DOWNLOAD YOUR TICKET!");
                }, 5000);
                
            });
        }, 500);
    }

    return (
        <>
            <Layout title="Download Ticket">
                <button className="block w-full text-sky-500 hover:bg-sky-500 hover:text-white transition font-bold text-center p-4 my-32 text-lg border-2 rounded-lg border-sky-500 max-w-lg mx-auto" onClick={downloadTicketPDF.bind(this)}>{downloadMessage}</button>
                <div className="w-full flex justify-center w-0 h-0 overflow-hidden">
                    <div id="ticketel" className="mx-10 py-12">
                        <div className="rounded-xl overflow-hidden p-8 border w-128 bg-white">
                            <div className="text-center">
                                <div className="text-center w-full mb-16 mt-8">
                                    <img className="w-48 inline-block" src={ppclogo} alt="Premier Club"/>
                                </div>
                                <div className="w-full uppercase text-gray-800">
                                    <div className="self-start w-full mb-8">
                                        <h1 className="font-black text-2xl md:text-4xl mb-1 md:mb-2">{ticketdata.membername}</h1>
                                        <h2 className="md:font-black">THIS TICKET IS VALID FOR {ticketdata.ticketcount} PERSON</h2>
                                    </div>
                                    <div className="w-full">
                                        <h5 className="mb-2 font-black text-lg md:text-2xl">{formatedeventdate}</h5>
                                        <h1 className="font-bold text-xl md:text-3xl">{ticketdata.eventname}</h1>
                                        <h3 className="text-md">{ticketdata.eventlocation}</h3>
                                    </div>
                                </div>
                                <div className="p-3 text-center mt-6">
                                    <div className="bg-white p-3 w-auto inline-block rounded-lg">
                                        <QRCode size={200} value={ticketdata.ticketcipher} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
             
        </>
    );
}

export default DownloadTicket;