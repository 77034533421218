import React, { useState, useContext } from "react";
import { signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { auth } from "./service/firebase";
import { AuthContext } from "./service/auth";

import Layout from "./components/Layout"

import headerBackground from "./assets/header-bg.jpg";

const Login = () => {
  const location = useLocation();
  var getreturnurl = "/";
  if(location.state){
    getreturnurl = location.state.returnurl;
  }
  const returnurl = getreturnurl;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  
  if(currentUser){
    navigate("/");
  }

  const [formerrorstate, setFormerrorstate] = useState(false);
  const [formfberrorstate, setFormfberrorstate] = useState(false);
  const [formfberrortext, setFormfberrortext] = useState("");
  const [formbuttontext, setFormButtontext] = useState("LOG IN");

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormerrorstate(false);
    setFormButtontext("PLEASE WAIT...");
    function onRegister() {
      signInWithEmailAndPassword(auth, email, password)
      .then(function(firebaseUser) {
        var userFname = firebaseUser.user.displayName.split(' ').shift();
        setFormButtontext("HI, " + userFname);
        if(returnurl){
          navigate(returnurl);
        } else {
          navigate("/");
        }
        
      })
      .catch(function(error) {
        setFormerrorstate(true);
        setFormButtontext("LOG IN");
      });
    }
    onRegister();
  };

  const signInWithFacebook = ()=>{
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
    .then((result) => {
      if(returnurl){
        navigate(returnurl);
      } else {
        navigate("/");
      }

    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      if(errorCode === "auth/account-exists-with-different-credential"){
        setFormfberrortext("YOU ALREADY HAVE AN ACCOUNT WITH THIS EMAIL");
        setFormfberrorstate(true);
      }
      
    });
  }

  const inputclass = "block w-full px-6 py-4 mb-4 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none";

  return (
    <Layout title="Login">
      {getreturnurl != "/" &&
        <div className="bg-white text-sky-500 text-center font-bold text-2xl uppercase mb-8 border rounded-xl px-4 py-12">Please Login to Continue!</div>
      }
      <div className="block md:flex bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="w-full bg-200 md:w-3/6 bgmoveanimation" style={{ backgroundImage: `url(${headerBackground})` }}>
          <div className="px-4 py-12 md:p-2 w-full h-full bg-sky-500 rounded-t-xl md:rounded-l-xl md:rounded-t-none bg-opacity-10 items-center text-center justify-center flex backdrop-blur-3xl">
            <h1 className="text-white font-bold text-3xl tracking-widest">LOGIN</h1>
          </div>
        </div>
        <div className="w-full md:w-3/6 px-6 py-8 md:px-12 md:py-16">
          <div className={`text-red-500 font-bold text-xs bg-red-300 text-center p-4 rounded-lg border border-red-500 mb-4 ${formerrorstate ? "block" : "hidden"}`}>EMAIL OR PASSWORD DOESN'T MATCH</div>
          <form className="loginForm" onSubmit={handleSubmit}>
              <input
              placeholder="Email Address"
              type="email"
              name="email"
              required
              className={inputclass}
              onChange={(e) => setEmail(e.target.value)}
              ></input>
              <input
              placeholder="Password"
              type="password"
              name="password"
              required
              className={inputclass}
              onChange={(e) => setPassword(e.target.value)}
              ></input>
              <div className="block">
                  <button className="w-full px-5 py-4 font-bold text-white bg-sky-500 rounded-lg uppercase border-2 border-sky-500 hover:bg-sky-600 hover:border-sky-600 transition">{formbuttontext}</button>
                  <p className="text-center text-xs mt-4"><Link to="/password-reset/">NEED HELP WITH PASSWORD?</Link></p>
              </div>
              <div className="flex items-center justify-center border-t-[1px] border-t-slate-300 w-full relative mt-16">
                <div className="-mt-1 font-bod bg-white px-5 absolute font-bold text-xs">NEED AN ACCOUNT?</div>
              </div>
              <div className="block mt-8">
                  <Link to="/signup/" className="w-full block text-center border-2 px-5 py-4 font-bold text-sky-500 border-sky-500 rounded-lg uppercase hover:bg-sky-500 hover:text-white transition">CLICK HERE TO REGISTER</Link>
              </div>
          </form>
          <div>
            <div className="flex items-center justify-center border-t-[1px] border-t-slate-300 w-full relative mt-12">
              <div className="-mt-1 font-bod bg-white px-5 absolute font-bold text-xs">OR</div>
            </div>
            <div className="mt-12">
              <button onClick={signInWithFacebook} className="w-full block text-center px-5 py-5 font-bold text-white bg-blue-500 rounded-lg uppercase hover:bg-blue-600 hover:text-white transition">LOGIN WITH FACEBOOK</button>
              <div className={`text-red-500 font-bold text-xs bg-red-300 text-center p-4 rounded-lg border border-red-500 mt-4 uppercase ${formfberrortext ? "block" : "hidden"}`}>{formfberrortext}</div>
            </div>
          </div>
        </div>
      </div>
      
    </Layout>
  );
};

export default Login;