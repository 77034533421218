import React from "react";
import Layout from "./components/Layout"
function NotFound() {
    
    return (
        <Layout title="404 - NOT FOUND">
            <div className="text-center py-32">
                <h1 className="font-black text-sky-400 text-8xl mb-4">404</h1>
                <p className="font-bold tracking-widest text-xl text-sky-500">PAGE NOT FOUND</p>
            </div>
        </Layout>
    );
}

export default NotFound;