import React from "react";

import Layout from "./components/Layout"

import headerBackground from "./assets/header-bg.jpg";

function ContactUs() {
    
    return (
        <Layout title="Contact Us">
            <div className="rounded-xl shadow-lg bg-white flex flex-wrap overflow-hidden">
                <div className="w-full bg-200 bgmoveanimation rounded-t-xl" style={{ backgroundImage: `url(${headerBackground})` }}>
                    <div className="px-4 py-8 md:p-24 w-full h-full rounded-t-xl bg-sky-500 bg-opacity-10 items-center text-center backdrop-blur-3xl text-white uppercase">
                        <h1 className="font-bold text-4xl">CONTACT US</h1>
                    </div>
                </div>
                <div className="w-full px-6 py-8 md:px-12 md:py-12">
                    <section className="h-auto bg-white">
                        <div className="px-4 py-12 mx-auto max-w-7xl">
                            <div className="w-full mx-auto text-left">
                                <h1 className="mb-6 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-7xl md:tracking-tight uppercase">FEEL FREE TO <span className="block w-full text-transparent bg-clip-text bg-gradient-to-r from-sky-400 via-sky-600 to-blue-700 lg:inline">CONTACT</span>.</h1>
                                <div className="text-lg text-gray-600 md:text-xl">
                                    <p className="mb-6">If you have any questions about our club or us, feel free to message us on our Facebook page: <strong>facebook.com/premierprofessionalsclub</strong>.</p>
                                    <p className="mb-6">If you have any issues with this website, please message us on the Facebook page or call 01841206040.</p>
                                    <p>If you are a club member, the best option is to post in our Facebook group.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
}

export default ContactUs;