import React, { useState, useEffect } from "react";
import {Link } from "react-router-dom";
import { db } from "../service/firebase";
import { collection, query, limit, onSnapshot, orderBy } from "firebase/firestore";
import { LocationMarkerIcon } from '@heroicons/react/outline'

import heroBackground from "../assets/hero-bg.jpg";
import networkingImage1 from "../assets/networking-image-1.jpg";
import networkingImage2 from "../assets/networking-image-2.jpg";
import networkingImage3 from "../assets/networking-image-3.jpg";
import networkingImage4 from "../assets/networking-image-4.jpg";

function Landing() {
    const [eventslist, setEventslist] = useState([]);
    useEffect(() => { 
        const geteventslist = onSnapshot(query(collection(db, "events"), limit(5), orderBy("createdAt", "desc")), snap => {
          const data = snap.docs.map(doc => doc)
          setEventslist(data);
        });
 
        return () => geteventslist()
        
    }, []);

    const renderEvents = eventslist.map(function(data, idx) {
        const eventdata = data.data();
        const eventdate = new Date(eventdata.eventdate); 
        const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        
        return(
            <div key={idx}>
                <div className="block md:flex flex-wrap bg-white border shadow-sm mb-6 rounded-xl overflow-hidden p-4 items-center justify-center">
                    <div className="w-auto bg-200 md:w-3/12 overflow-hidden">
                        <div className="p-4 w-full h-full border text-center rounded-lg">
                            <div className="text-sky-500 font-bold text-xs uppercase py-2 md:py-0">{formatedeventdate}</div>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 md:pl-4 mb-4 md:mb-0">
                        <h1 className="uppercase font-bold text-lg mb-1">{eventdata.eventname}</h1>
                        <p className="uppercase flex text-xs"><LocationMarkerIcon className="w-3 h-3 mr-1"/><span>{eventdata.eventlocation}</span></p>
                    </div>
                    <div className="w-full md:w-3/12 md:pl-4">
                        <Link className="bg-sky-500 hover:bg-sky-600 transition block text-center text-white font-bold text-md rounded-lg p-3" to={`/event/${eventdata.eventslug}/`}>BOOK A SEAT</Link>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div>
            <div className="bg-white bg-cover bg-center rounded-xl shadow-lg px-6 py-12 md:px-12 md:py-28 backdrop backdrop-blur-3xl" style={{ backgroundImage: `url(${heroBackground})` }}>
                <div className="text-center">
                    <div className="text-5xl font-bold text-gray-800 leading-none">Welcome to Premier Club!</div>
                    <div className="mt-6 text-xl font-light text-true-gray-500 antialiased">A club of top rated freelancers of Bangladesh.</div>
                </div>
                <div className="text-center mt-20">
                    <div className="flex items-center justify-center w-auto h-12 rounded-full bg-cool-gray-100 text-gray-800 animate-bounce hover:text-gray-900 hover:bg-cool-gray-50 transition duration-300 ease-in-out cursor-pointer">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                        </svg>
                    </div>
                    <div className="mt-4">
                        <Link to="/signup/" className="font-bold mt-6 px-8 py-4 rounded-full tracking-wide bg-sky-400 text-white outline-none focus:outline-none hover:shadow-lg hover:bg-sky-600 transition duration-200 ease-in-out">JOIN OUR CLUB!</Link>
                    </div>
                </div>
            </div>
            <section className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] overflow-hidden">
                <div className="container">
                    <div className="flex flex-wrap justify-between items-center -mx-4">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex items-center -mx-3 sm:-mx-4">
                                <div className="w-full xl:w-1/2 px-3 sm:px-4">
                                    <div className="py-3 sm:py-4">
                                        <img
                                            src={networkingImage1}
                                            alt=""
                                            className="rounded-2xl w-full"
                                            />
                                        
                                    </div>
                                    <div className="py-3 sm:py-4">
                                        <img
                                            src={networkingImage4}
                                            alt=""
                                            className="rounded-2xl w-full"
                                            />
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/2 px-3 sm:px-4">
                                    <div className="py-3 sm:py-4">
                                        <img
                                            src={networkingImage3}
                                            alt=""
                                            className="rounded-2xl w-full"
                                            />
                                        
                                    </div>
                                    <div className="py-3 sm:py-4">
                                        <img
                                            src={networkingImage2}
                                            alt=""
                                            className="rounded-2xl w-full"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
                            <div className="mt-10 lg:mt-0">
                                <span className="font-semibold text-lg text-primary mb-2 block">WHO WE ARE?</span>
                                <h2 className="font-bold text-3xl sm:text-4xl text-dark mb-8">
                                    We are a community of top freelancers of Bangladesh.
                                </h2>
                                <p className="text-base text-body-color mb-8">
                                    Premier Professionals Club is a community of all top-rated freelancers of Bangladesh. Our motto is networking, skill sharing, get help from others.
                                </p>
                                <p className="text-base text-body-color">
                                    Here in the club, we arrange premier events every 3-4 months. Some events are for skill updates, some for specific problem solving, and some events are completely for fun and networking.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white shadow-lg rounded-xl p-16 relativez-20o verflow-hidden">
                <div className="container">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full px-4">
                            <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
                            <span className="font-semibold text-lg text-primary mb-2 block">
                            NETWORKING AND KNOWLEDGE SHARING SESSIONS
                            </span>
                            <h2
                                className="
                                font-bold
                                text-3xl
                                sm:text-4xl
                                md:text-[40px]
                                text-dark
                                mb-4
                                "
                                >
                                JOIN OUR EVENTS
                            </h2>
                            <p className="text-base text-body-color">
                            Premier Club hosts knowledge sharing and community networking events every few months. Feel free to join our events.
                            </p>
                            </div>
                        </div>
                    </div>
                    {renderEvents}
                </div>
            </section>

            <section className="py-20 lg:py-[120px]">
            <div className="container">
                <div
                    className="
                    bg-sky-400
                    relative
                    rounded-xl
                    overflow-hidden
                    py-12
                    px-8
                    md:p-[70px]
                    z-10
                    "
                    >
                    <div className="flex flex-wrap items-center -mx-4">
                        <div className="w-full lg:w-2/3 px-4">
                        <span className="text-white text-base font-semibold mb-2 uppercase">
                        Become a member
                        </span>
                        <h2
                            className="
                            text-white
                            font-bold
                            text-3xl
                            sm:text-[38px]
                            leading-tight
                            mb-6
                            sm:mb-8
                            lg:mb-0
                            "
                            >
                            Register Now to Get Access
                        </h2>
                        </div>
                        <div className="w-full lg:w-1/3 px-4">
                        <div className="flex flex-wrap lg:justify-end">
                            <Link
                                to="/signup/"
                                className="
                                inline-block
                                py-4
                                px-6
                                md:px-9
                                lg:px-6
                                xl:px-9
                                rounded
                                text-base
                                font-medium
                                bg-sky-600
                                transition
                                hover:bg-opacity-90
                                text-white
                                my-1
                                "
                                >
                            REGISTER
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div>
                        <span className="absolute top-0 left-0 z-[-1]">
                        <svg
                            width="189"
                            height="162"
                            viewBox="0 0 189 162"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <ellipse
                                cx="16"
                                cy="-16.5"
                                rx="173"
                                ry="178.5"
                                transform="rotate(180 16 -16.5)"
                                fill="url(#paint0_linear)"
                                />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="-157"
                                    y1="-107.754"
                                    x2="98.5011"
                                    y2="-106.425"
                                    gradientUnits="userSpaceOnUse"
                                    >
                                    <stop stopColor="white" stopOpacity="0.07" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        </span>
                        <span className="absolute bottom-0 right-0 z-[-1]">
                        <svg
                            width="191"
                            height="208"
                            viewBox="0 0 191 208"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <ellipse
                                cx="173"
                                cy="178.5"
                                rx="173"
                                ry="178.5"
                                fill="url(#paint0_linear)"
                                />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="-3.27832e-05"
                                    y1="87.2457"
                                    x2="255.501"
                                    y2="88.5747"
                                    gradientUnits="userSpaceOnUse"
                                    >
                                    <stop stopColor="white" stopOpacity="0.07" />
                                    <stop offset="1" stopColor="white" stopOpacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        </span>
                    </div>
                </div>
            </div>
            </section>
        </div>
    );
}

export default Landing;