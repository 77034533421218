import React, { useState, useContext, useEffect } from "react";
import { db } from "./service/firebase";
import { AuthContext } from "./service/auth";
import { useNavigate, Link } from "react-router-dom";
import { collection, query, limit, onSnapshot, orderBy, doc, getDoc, updateDoc, where } from "firebase/firestore";
import Layout from "./components/Layout"
import QrReader from 'modern-react-qr-reader'

import heroBackground from "./assets/hero-bg.jpg";

function ScanTicket() {
    const { currentUser } = useContext(AuthContext);
    const [useradmin, setUseradmin] = useState(false);
    const [showscanner, setShowscanner] = useState(false);
    const [enablescanner, setEnablescanner] = useState(true);
    const [ticketdatadisplay, setTicketdatadisplay] = useState(false);
    const [ticketid, setTicketid] = useState("");
    const [membername, setMembername] = useState("");
    const [membercount, setMembercount] = useState(0);
    const [totalparticipants, setTotalparticipants] = useState(0);
    const [remainingparticipants, setRemainingparticipants] = useState(0);
    const [eventslist, setEventslist] = useState([]);
    const [selectevent, setSelectevent] = useState("selectevent");
    const [selecteventname, setSelecteventname] = useState("");

    var CryptoJS = require("crypto-js");

    useEffect(() => {
        if (currentUser) {

            const docRef = doc(db, "admins", currentUser.uid);

            getDoc(docRef)
            .then((snap) => {
                if (!snap.exists()) throw new Error(""); 
                if(snap.data().role === "admin"){
                    setUseradmin(true);
                }
                
            });
        }
        
    }, [currentUser]);

    useEffect(() => { 
        const geteventslist = onSnapshot(query(collection(db, "events"), limit(5), orderBy("createdAt", "desc")), snap => {
          const data = snap.docs.map(doc => doc)
          setEventslist(data);
        });
 
        return () => geteventslist()
        
    }, []);

    const renderEvents = eventslist.map(function(data, idx) {
        const eventid = data.id;
        const eventdata = data.data();
        
        return(
            <option key={idx} value={eventid}>{eventdata.eventname}</option>
        );
    });

    const handleScanner = (e) => {
        setShowscanner(true);
        handlenewScan();
    }

    function handleQRScan(data){
        if(data){
            
            setEnablescanner(false);
            renderTicketData(data);
        }
    }

    function handleQRScanError(){

    }

    const getTicketData = async (id) => {
        const ticketSnapshot = await getDoc(doc(db, 'tickets', id));
        if (ticketSnapshot.exists()) {
            var thisticketdata = ticketSnapshot.data();
            if(thisticketdata.eventid === selectevent){
                if(thisticketdata.evententrytime){
                    alert("This participant already entered in the event.");
                    handlenewScan();
                } else {
                    setMembername(thisticketdata.membername);
                    setMembercount(thisticketdata.ticketcount);
                }
                
            } else {
                alert("This is not a ticket of this event.");
                handlenewScan();
            }
        } else {
                alert("Something wrong.");
                handlenewScan();
        }
    };

    function renderTicketData(ticketidchipher){
        var getticketchipherbytes = CryptoJS.AES.decrypt(ticketidchipher, 'SolaymanTheBest75');
            var getticketuid = getticketchipherbytes.toString(CryptoJS.enc.Utf8);
            setTicketid(getticketuid);
            setTicketdatadisplay(true);
            getTicketData(getticketuid);
    }

    const handlenewScan = (e) => {
        setTicketdatadisplay(false);
        setEnablescanner(true);
        setMembername("");
        setMembercount(0);
    }

    const handleConfirmEntry = (e) => {
        setMembername("");
        setMembercount(0);
        const ticketDbRef = doc(db, "tickets", ticketid);
        updateDoc(ticketDbRef, {
            evententry: "done",
            evententrytime: Date.now(),
            evententryby: currentUser.displayName
        }).then(() => {
            setTicketdatadisplay(false);
            setEnablescanner(true);
        })


        
        
    }

    useEffect(() => { 
        const getticketlist = onSnapshot(query(collection(db, "tickets"), where("eventid", "==", selectevent)), snap => {

            var thiseventticketcounter = 0;
            var thiseventremainingticketcounter = 0;

            snap.docs.map(function(doc, idx){
                if(doc.data().payment === "paid"){
                    thiseventticketcounter += Number(doc.data().ticketcount);
                }
                if(doc.data().evententrytime){
                    thiseventremainingticketcounter += Number(doc.data().ticketcount);
                }
            });
            setTotalparticipants(thiseventticketcounter);
            setRemainingparticipants(thiseventticketcounter - thiseventremainingticketcounter);
        }); 
    }, [selectevent]);

    return (
        <div className="relative min-h-screen">
            {showscanner && enablescanner &&
                <div className="bg-white absolute top-0 left-0 z-900 w-screen min-h-screen overflow-hidden">
                    <QrReader
                    delay={500}
                    facingMode={"environment"}
                    onError={handleQRScanError}
                    onScan={handleQRScan}
                    className="w-screen h-auto bg-gray-900"
                    />
                    <div className="text-center text-sky-500 font-bold text-lg px-6 py-12 uppercase">
                        <h2>{selecteventname}</h2>
                        <div className="mt-8">
                            <p className="text-5xl mb-2">{remainingparticipants}</p>
                            <p className="text-xs tracking-widest">PERSONS REMAINING</p>
                        </div>
                        <p className="text-sm mt-4 text-gray-800">TOTAL {totalparticipants} PARTICIPANTS</p>
                    </div>
                </div>
            }
            {ticketdatadisplay &&
                <div className="bg-white absolute top-0 left-0 z-900 w-screen min-h-screen overflow-hidden flex flex-wrap items-center justify-center">
                    <div className="text-center p-6 w-full">
                        {membername ?
                            <div className="font-bold text-sky-500 py-12 uppercase">
                                <div className="text-sky-500 text-2xl mb-4">{membername}</div>
                                <div className="text-sky-600 text-md">TICKET FOR {membercount} PERSON.</div>
                                <button onClick={handleConfirmEntry.bind(this)} className="block mx-auto w-full px-6 py-4 mt-12 border bg-green-500 hover:bg-green-600 text-white rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none text-center">CONFIRM ENTRY</button>
                            </div>
                        :
                            <div className="text-xl font-bold text-sky-500 py-12">PLEASE WAIT...</div>
                        }
                        
                        
                        <button onClick={handlenewScan.bind(this)} className="block mx-auto w-full px-6 py-4 mb-6 border bg-sky-500 hover:bg-sky-600 text-white rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none text-center">SCAN NEW PARTICIPANT</button>
                    </div>
                </div>
            }
            <Layout title="Scan Ticket">
                {useradmin &&
                <div className="block bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="w-full bg-cover bg-center" style={{ backgroundImage: `url(${heroBackground})` }}>
                        <div className="block px-4 py-24 w-full h-full bg-sky-500 rounded-t-xl bg-opacity-10 text-center">
                            <h1 className="block w-full text-sky-500 font-bold text-3xl tracking-widest">SCAN TICKETS</h1>
                        </div>
                    </div>
                    <div className="w-full px-6 py-8 md:px-12 md:py-16">
                        <div className="block w-full">
                            <div className="">
                                <select required type="number" name="selectevent" className="block mx-auto w-full px-6 py-4 mb-6 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none text-center" value={selectevent} onChange={(e) => {setSelectevent(e.target.value);setSelecteventname(e.target.options[e.target.selectedIndex].text)}}>
                                    <option eventname="fsdsf" value="selectevent">Select Event</option>
                                    {renderEvents}
                                </select>
                                {selectevent !== "selectevent" &&
                                    <button className="block mx-auto w-full px-6 py-4 mb-6 border bg-sky-500 hover:bg-sky-600 text-white rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none text-center" onClick={handleScanner.bind(this)}>START SCANNING</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Layout>
        </div>
    );
}

export default ScanTicket;