import ppcicon from "../assets/ppc-icon.png"

function Loading() {
    return (
        <>
            <div className="shadow-md rounded-xl p-8 w-full mx-auto bg-white mb-8">
                <div className="animate-pulse flex space-x-8 items-center">
                    <div className="rounded-lg bg-slate-200 h-40 w-60"></div>
                    <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-3"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200 rounded"></div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loading;