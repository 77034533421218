import React, { useState, useEffect, useContext } from "react";
import { db } from "./service/firebase";
import { AuthContext } from "./service/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { collection, query, where, limit, getDocs, setDoc, doc, docs, onSnapshot, orderBy } from "firebase/firestore";

import { LocationMarkerIcon, CalendarIcon } from '@heroicons/react/outline'

import Layout from "./components/Layout"
import Loading from './components/Loading';


import headerBackground from "./assets/header-bg.jpg";

const parse = require('html-react-parser');

function EventDetails({triggerbooknow}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { currentUser } = useContext(AuthContext);
    const [isapploading, setIsapploading] = useState(true);
    const [showparticipantavatarlist, setShowparticipantavatarlist] = useState(false);
    const [thiseventdata, setThiseventdata] = useState([]);
    const [eventsticketcount, setEventsticketcounter] = useState(1);
    const [eventsunpaidticketcount, setEventunpaidsticketcounter] = useState(3);
    const [userticketcount, setUserunpaidsticketcounter] = useState(0);

    const [participantsarraystate, setParticipantsarraystate] = useState([]);
    const [participantsshufflearraystate, setParticipantsshufflearraystate] = useState([]);

    const handleBookNow = (eventid, eventtotalseat, eventdata, isnaked) => {
        if(currentUser){
            navigate("/book-now/",{state :{ eventid : eventid, eventtotalseat : eventtotalseat, eventdata: eventdata, isnaked: isnaked}, replace:true});
        } else {
            navigate("/login/", {state : {returnurl: location.pathname}});
        }
        
    };

    useEffect(() => { 
        const geteventslist = onSnapshot(query(collection(db, "events"), where("eventslug", "==", params.slug)), snap => {
          const data = snap.docs.map(doc => doc)
          setThiseventdata(data);
          if(!triggerbooknow){
            setIsapploading(false);
          }
        });
 
        return () => geteventslist()
        
    }, []);


    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
        
            // Generate random number
            var j = Math.floor(Math.random() * (i + 1));
                        
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
            
        return array;
    }

    useEffect(() => { 
        thiseventdata.map(function(data, idx) {
            const eventdata = data.data();
            var thiseventidx = idx;
            var thiseventid = data.id;
            var thiseventtotalseat = eventdata.eventcapacity;
            const eventdate = new Date(data.eventdate); 
            const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
            const getticketlist = onSnapshot(query(collection(db, "tickets"), where("eventid", "==", data.id), orderBy("createdAt", "asc")), snap => {
                
                var thiseventticketcounter = 0;
                var thiseventunpaidticketcounter = 0;
                var thiseventuserticketcounter = 0;

                var participantsarray = [];
                var participantsshufflearray = [];

                snap.docs.map(function(doc, idx){
                    thiseventticketcounter += Number(doc.data().ticketcount);
                    if(doc.data().payment === "unpaid"){
                        thiseventunpaidticketcounter += Number(doc.data().ticketcount);
                    }
                    if(doc.data().payment === "paid" && doc.data().useruid === currentUser.uid){
                        thiseventuserticketcounter += Number(doc.data().ticketcount);
                        setUserunpaidsticketcounter(thiseventuserticketcounter);
                    }

                    if(doc.data().payment === "paid"){
                        let participantobj = {
                            name: doc.data().membername,
                            email: doc.data().memberemail,
                            ticket: doc.data().ticketcount
                        };

                        participantsarray.push(participantobj);
                        participantsshufflearray.push(participantobj);
                        
                    }
                    
                });
                var thiseventticketavailable = thiseventtotalseat - thiseventticketcounter;

                setEventsticketcounter(thiseventticketavailable);
                setEventunpaidsticketcounter(thiseventunpaidticketcounter);

                setParticipantsarraystate(participantsarray);

                shuffleArray(participantsshufflearray);

                setParticipantsshufflearraystate(participantsshufflearray);
                
            }); 

            document.title = eventdata.eventname + " | Premier Events";

            console.log(triggerbooknow);
            

        });
    }, [thiseventdata]);

    var md5 = require("md5");
    
    const participantsarraystateshort = participantsshufflearraystate.slice(0, 8);

    const participantsavatarshort = participantsarraystateshort.map((participant, idx) => {
        var gravatarurl = "https://www.gravatar.com/avatar/"+md5(participant.email)+"?s=50&d=wavatar";
        return(
            <div key={idx} className="my-1 md:my-0 -ml-4">
                <img src={gravatarurl} className="w-10 border-2 border-white rounded-full" alt={participant.name} title={participant.name}/>
            </div>
        )
    });

    const participantsavatarfull = participantsarraystate.map((participant, idx) => {
        var gravatarurl = "https://www.gravatar.com/avatar/"+md5(participant.email)+"?s=50&d=wavatar";
        return(
            <div key={idx} className="bg-white rounded-lg shadow-lg p-2">
                <div className="flex items-center">
                    <img src={gravatarurl} className="w-8 rounded-md mr-3" alt={participant.name} title={participant.name}/>
                    <div className="uppercase">
                        <h4 className="text-sm font-bold">{participant.name}</h4>
                        <p className="w-full text-xxs">BOOKED FOR {participant.ticket} PERSON{participant.ticket > 1 && "S"}</p>
                    </div>
                    
                </div>
            </div>
        )
    });

    const handleSeeMoreParticipants = (e) => {
        setShowparticipantavatarlist(true);
    }


    const renderEvents = thiseventdata.map(function(data, idx) {
        const eventdata = data.data();
        const eventdate = new Date(eventdata.eventdate); 
        const eventclosedate = new Date(eventdata.eventregclosedate + "T23:59:00"); 
        const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        const formatedeventclosedate = eventclosedate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });

        const currentdate = new Date();
        const fotmattedcurrentdate = currentdate.toLocaleString('en-US', { timeZone: 'Asia/Dhaka' })
        const fotmattedregclosingdate = eventclosedate.toLocaleString('en-US', { timeZone: 'Asia/Dhaka' })

        var thiseventregisclose = false;
        if(new Date(fotmattedcurrentdate).getTime() > new Date(fotmattedregclosingdate).getTime()){
            thiseventregisclose = true;
        }

        if(triggerbooknow && !thiseventregisclose){
            handleBookNow(data.id, eventdata.eventcapacity, eventdata, true)
        }

        return(
            <div key={idx}>
                <div className="rounded-xl shadow-lg bg-white flex flex-wrap overflow-hidden">
                    <div className="w-full bg-200 bgmoveanimation rounded-t-xl" style={{ backgroundImage: `url(${headerBackground})` }}>
                        <div className="px-4 py-8 md:p-24 w-full h-full rounded-t-xl bg-sky-500 bg-opacity-10 items-center text-center backdrop-blur-3xl text-white uppercase group">
                            <p className="text-sm tracking-widest text-sky-500 mb-4">PREMIER EVENT</p>
                            <h1 className="font-bold text-4xl mb-4 md:mb-2">{eventdata.eventname}</h1>
                            <p className="md:flex items-center justify-center"><CalendarIcon className="hidden h-4 md:inline-flex w-4 mr-1"/><span className="block md:inline-flex md:mr-4">{formatedeventdate}</span><LocationMarkerIcon className="hidden h-4 md:inline-flex w-4 mr-1"/><span className="block md:inline-flex">{eventdata.eventlocation}</span></p>
                            <div className="mt-12 md:-mb-12">
                                <div className="tracking-widest text-xs mb-2">- PARTICIPANTS -</div>
                                <div className="flex items-center justify-center">
                                    {participantsavatarshort}
                                    {participantsarraystate.length > 8 &&
                                        <a href="#participantssection" onClick={handleSeeMoreParticipants} className="my-1 md:my-0 -ml-4 bg-white h-10 w-10 p-2 text-xs flex items-center justify-center text-sky-400 font-bold rounded-full cursor-pointer relative">+{participantsarraystate.length - 8}
                                            <div id="tooltip-default" role="tooltip" class="inline-block absolute left-0 -bottom-20 visible z-10 py-2 px-3 text-sm font-medium text-white bg-sky-600 rounded-lg shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip animate-bounce">SEE ALL PARTICIPANTS
                                                <svg class="absolute left-1 -top-2 z-10 w-6 h-6 text-sky-600 transform fill-current stroke-current" width="8" height="8">
                                                    <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
                                                </svg>
                                            </div>
                                            
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {eventsticketcount === 0 && eventsunpaidticketcount >= 1 &&
                        <div className="p-12 bg-yellow-300 text-lg w-full text-center text-yellow-900 font-medium">
                            <h2 className="text-3xl font-black">NO SEAT AVAILABLE</h2>
                            <p className="mt-4">Unpaid booking got cleared from the server after 40 minutes of creation.<br/>So You still have a chance, Please check back again after 40 minutes.</p>
                        </div>
                    }
                    <div className="flex flex-wrap w-full">
                        <div className="w-full md:w-4/6 md:h-full flex flex-wrap order-2 md:order-1">
                            <div className="px-6 py-6 md:px-16 md:py-12 content_el w-full single_content" data-color-mode="light">
                                {userticketcount > 0 &&
                                    <div className="mb-10 font-bold bg-green-200 text-green-700 text-md rounded-xl px-2 py-6 border-dashed border-2 border-green-500 text-center">
                                        YOU'VE BOOKED {userticketcount} TICKET{userticketcount > 1 && "S"} FOR THIS EVENT
                                    </div>
                                }
                                {parse(eventdata.eventdetails)}
                            </div>

                            {thiseventregisclose ?
                                <>
                                    <div className="self-end w-full bg-gray-500 p-5 transition text-center font-bold text-white text-lg">REGISTRATION CLOSED</div>
                                </>
                            :
                                <>
                                    {eventsticketcount === 0 ? 
                                        <>
                                            <div className="self-end w-full bg-gray-500 p-5 transition text-center font-bold text-white text-lg">HOUSEFULL</div>
                                        </>
                                    : 
                                        <>
                                            {currentUser ? 
                                                <div className="self-end w-full bg-sky-500 p-5 hover:bg-sky-600 transition text-center cursor-pointer font-bold text-white text-lg" onClick={handleBookNow.bind(this, data.id, eventdata.eventcapacity, eventdata, false)}>BOOK YOUR SEAT!</div>
                                            :
                                                <div className="self-end w-full bg-sky-500 p-5 hover:bg-sky-600 transition text-center cursor-pointer font-bold text-white text-lg" onClick={handleBookNow.bind(this, data.id, eventdata.eventcapacity, eventdata, false)}>PLEASE LOGIN TO PAY</div>
                                            }
                                            
                                        </>
                                    }
                                </>
                            }
                            
                        </div>
                        <div className="bg-sky-50 w-full md:w-2/6 px-6 py-6 md:px-12 md:py-12 md:h-full text-center order-1 md:order-2">
                            {currentUser &&
                                <>
                                    <div className="w-full bg-white shadow rounded-xl overflow-hidden mb-6">
                                        <div className="text-white bg-sky-500 font-bold text-lg p-3">SEAT AVAILABLE</div>
                                        <div className="font-bold text-5xl p-3 text-sky-500">{eventsticketcount}</div>
                                    </div>
                                    {eventsunpaidticketcount >= 1 &&
                                        <div className="mb-6 bg-sky-200 text-sky-700 text-md rounded-xl px-2 py-6 border-dashed border-2 border-sky-500">
                                            <span className="font-bold">{eventsunpaidticketcount} SEATS ARE ON HOLD</span><br/>
                                            <span className="text-xs">(IF NOT PAID) THESE WILL BE AVAILABLE IN 40 MINUTES!</span>
                                        </div>
                                    }
                                </>
                            }
                            
                            <div className="w-full bg-white shadow rounded-xl overflow-hidden mb-6 border-double border-8 border-sky-500">
                                <div className="bg-sky-500 font-bold text-white p-4">
                                    <div className="text-lg">TOTAL SEAT</div>
                                    <div className="text-5xl">{eventdata.eventcapacity}</div>
                                </div>
                                <div className="bg-sky-600 font-bold text-white p-4">
                                    <div className="text-lg">ENTRY FEE</div>
                                    <div className="text-3xl">BDT {eventdata.evententryfee}</div>
                                </div>
                            </div>
                            {thiseventregisclose ?
                                <>
                                    <div className="w-full bg-sky-200 rounded-xl font-bold px-2 py-4 transition text-lg text-sky-500">
                                        REGISTRATION<br/>CLOSED
                                    </div>
                                </>
                            :
                                <>
                                    {eventsticketcount === 0 ? 
                                        <>
                                            <div className="w-full bg-sky-200 rounded-xl font-bold px-2 py-4 transition text-lg text-sky-500">
                                                HOUSEFULL
                                            </div>
                                        </>
                                    : 
                                        <>
                                            <button className="w-full relative px-2 py-5 shadow font-bold text-lg text-white transition duration-300 bg-green-400 rounded-md hover:bg-green-500 ease" onClick={handleBookNow.bind(this, data.id, eventdata.eventcapacity, eventdata, false)}>
                                                <span className="absolute bottom-0 left-0 h-full -ml-2">
                                                    <svg viewBox="0 0 487 487" className="w-auto h-full opacity-100 object-stretch" xmlns="http://www.w3.org/2000/svg"><path d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z" fill="#FFF" fillRule="nonzero" fillOpacity=".1"></path></svg>
                                                </span>
                                                <span className="absolute top-0 right-0 w-12 h-full -mr-3">
                                                    <svg viewBox="0 0 487 487" className="object-cover w-full h-full" xmlns="http://www.w3.org/2000/svg"><path d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z" fill="#FFF" fillRule="nonzero" fillOpacity=".1"></path></svg>
                                                </span>
                                                {currentUser ? 
                                                <span className="relative">BOOK YOUR SEAT!</span>
                                                :
                                                <span className="relative">LOGIN TO PAY</span>
                                                }
                                                
                                            </button>
                                            
                                            <p className="uppercase text-xs mt-4">Registration will close on <br/><strong>{formatedeventclosedate} AT 11:59 PM.</strong></p>
                                        </>
                                    }
                                </>
                            }
                            
                            
                        </div>
                    </div>
                </div>
                {showparticipantavatarlist &&
                    <div id="participantssection" className="pt-14">
                        <div className="px-6 py-8 md:px-16 md:py-16 rounded-xl shadow-lg bg-sky-100 overflow-hidden">
                            <div className="md:flex items-center justify-center md:border-t-[1px] border-t-sky-500 w-full relative mb-4 md:mb-12">
                                <div className="md:-mt-1 bg-sky-100 text-sky-500 md:px-5 md:absolute font-black text-xl md:text-3xl">PARTICIPANTS</div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                {participantsavatarfull}
                            </div>
                        </div>
                    </div>
                }
                
            </div>
        );
        
    });
    

    return (
        <>
        {triggerbooknow ?
            <><Loading /><Loading /></>
        :
            <Layout>
                <div className="">
                    {isapploading &&
                        <><Loading /><Loading /></>
                    }
                    {renderEvents}
                </div>
            </Layout>
        }
        </>
    );
}

export default EventDetails;