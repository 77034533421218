import React from "react";

import Layout from "./components/Layout"

import headerBackground from "./assets/header-bg.jpg";

function AboutUs() {
    
    return (
        <Layout title="About Us">
            <div className="rounded-xl shadow-lg bg-white flex flex-wrap overflow-hidden">
                <div className="w-full bg-200 bgmoveanimation rounded-t-xl" style={{ backgroundImage: `url(${headerBackground})` }}>
                    <div className="px-4 py-8 md:p-24 w-full h-full rounded-t-xl bg-sky-500 bg-opacity-10 items-center text-center backdrop-blur-3xl text-white uppercase">
                        <h1 className="font-bold text-4xl">ABOUT US</h1>
                    </div>
                </div>
                <div className="w-full px-6 py-8 md:px-12 md:py-12">
                    <section className="h-auto bg-white">
                        <div className="px-4 py-12 mx-auto max-w-7xl">
                            <div className="w-full mx-auto text-left">
                                <h1 className="mb-6 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-7xl md:tracking-tight uppercase">The community of <span className="block w-full text-transparent bg-clip-text bg-gradient-to-r from-sky-400 via-sky-600 to-blue-700 lg:inline">TOP RATED freelancers</span> of Bangladesh.</h1>
                                <div className="text-lg text-gray-600 md:text-xl">
                                    <p className="mb-6">Premier Professionals Club is a community of all top-rated freelancers of Bangladesh. Our motto is networking, skill sharing, get help from others.</p>
                                    <p className="mb-6">Here in the club, we arrange premier events every 3-4 months. Some events are for skill updates, some for specific problem solving, and some events are completely for fun and networking.</p>
                                    <p className="mb-6">We are a virtual community and the central point of our community is our Facebook group <strong>facebook.com/groups/premierprofessionalsclub</strong></p>
                                    <p className="mb-6">We are a non-profit community. We (Including every member and admins) do not make any profit from our events. We publish total accounts and costs after every event we host. Sometimes, we refund event participants if any money is left after the event.</p>
                                    <p>Our admin panel also not fixed, it is always open to changes of our admin panel. We always include/remove admins from the admin panel as needed.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
}

export default AboutUs;