import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./service/auth";
import { useNavigate, Link } from "react-router-dom";
import { db } from "./service/firebase";
import { collection, query, where, onSnapshot, orderBy, limit } from "firebase/firestore";

import QRCode from "react-qr-code";

import Layout from "./components/Layout"
import Loading from './components/Loading';

import headerBackground from "./assets/header-bg.jpg";

import { LocationMarkerIcon, CalendarIcon, ClockIcon } from '@heroicons/react/outline'


function Tickets() {
    const { currentUser } = useContext(AuthContext);
    const [isapploading, setIsapploading] = useState(true);
    const [hasunpaidticket, setHasunpaidticket] = useState(false);
    const [ticketslist, setTicketslist] = useState([]);

    const navigate = useNavigate();

    var CryptoJS = require("crypto-js");

    useEffect(() => {
        if (currentUser) {
            const geteventslist = onSnapshot(query(collection(db, "tickets"), where("useruid", "==", currentUser.uid), limit(10), orderBy("createdAt", "desc")), snap => {
                const data = snap.docs.map(doc => doc)
                setTicketslist(data);
                setIsapploading(false);
            });
    
            return () => geteventslist()
        }
        
    }, [currentUser]);

    const handlePayNow = (thisticketid, ticketdata) => {
        var passbookingdata = {
            membername : ticketdata.membername,
            memberemail : ticketdata.memberemail,
            memberphone : ticketdata.memberphone,
            ticketcount : ticketdata.ticketcount,
            useruid : ticketdata.useruid,
            eventid : ticketdata.eventid,
            eventname : ticketdata.eventname,
            payable : ticketdata.payable,
            ticketid: thisticketid
        }
        navigate("/pay-now/",{state : {passbookingdata: passbookingdata}})
    };

    const handleDownload = (ticketdata, ticketcipher) => {
        var passticketdata = {
            membername : ticketdata.membername,
            ticketcount : ticketdata.ticketcount,
            eventname : ticketdata.eventname,
            eventdate : ticketdata.eventdate,
            eventlocation : ticketdata.eventlocation,
            payable : ticketdata.payable,
            ticketcipher: ticketcipher
        }
        navigate("/download-ticket/",{state : passticketdata});
    };

    const renderTickets = ticketslist.map(function(data, idx) {
        const ticketdata = data.data();
        var thisticketid = data.id;
        var eventdate = new Date(ticketdata.eventdate);
        var cipherticketnumber = CryptoJS.AES.encrypt(thisticketid, "SolaymanTheBest75").toString();
        const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        if(ticketdata.payment === "paid"){
            return(
                <div key={idx} className="mb-8 shadow rounded-xl overflow-hidden bg-200 md:bgmoveanimation" style={{ backgroundImage: `url(${headerBackground})` }}>
                    <div className="bg-sky-900 bg-opacity-50 rounded-xl overflow-hidden backdrop-blur-3xl p-8 relative">
                        <div className="text-md uppercase tracking-widest absolute z-70 top-0 right-0 bg-sky-500 w-auto h-auto text-center text-white font-bold px-8 py-1 rounded-bl-xl">PAID</div>
                        <div className="md:flex flex-wrap text-center md:text-left">
                            <div className="flex w-full md:w-4/6 flex-wrap grow uppercase text-white">
                                <div className="self-start w-full mb-8">
                                    <h1 className="font-black text-2xl md:text-4xl mb-1 md:mb-2">{ticketdata.membername}</h1>
                                    <h2 className="md:font-black">THIS TICKET IS VALID FOR {ticketdata.ticketcount} PERSON{ticketdata.ticketcount > 1 && "S"}</h2>
                                </div>
                                <div className="w-full">
                                    <h5 className="mb-2 font-black text-lg md:text-2xl">{formatedeventdate}</h5>
                                    <h1 className="font-bold text-xl md:text-3xl md:tracking-widest">{ticketdata.eventname}</h1>
                                    <h3 className="text-md">{ticketdata.eventlocation}</h3>
                                </div>
                                <div className="mt-6 self-end text-center md:text-left w-full">
                                    <button onClick={handleDownload.bind(this, ticketdata, cipherticketnumber)} className="bg-green-500 hover:bg-green-600 text-white font-bold text-lg px-12 py-3 rounded-lg">DOWNLOAD</button>
                                </div>
                            </div>
                            <div className="p-3 md:w-2/6 md:pl-8 text-center md:text-right mt-6 md:mt-0">
                                <div className="bg-white p-3 w-auto inline-block rounded-lg">
                                    <QRCode size={200} value={cipherticketnumber} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
        
    });


    const renderUnpaidTickets = ticketslist.map(function(data, idx) {
        const ticketdata = data.data();
        var thisticketid = data.id;
        var thisticketcreatedat = ticketdata.createdAt;
        var eventdate = new Date(ticketdata.eventdate);
        const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });

        const currentdate = new Date();
        var fotmattedcurrentdate = currentdate.toLocaleString('en-US', { timeZone: 'Asia/Dhaka' })
        var fotmattedregexpiredate = new Intl.DateTimeFormat('en-US', { timeZone: 'Asia/Dhaka', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(thisticketcreatedat)

        var currenttimestamp = new Date(fotmattedcurrentdate).getTime();
        var expiretimestamp = new Date(fotmattedregexpiredate).getTime();
        var bookingtimeage = currenttimestamp - expiretimestamp;
        var bookingtimeageinminutes = Math.floor((bookingtimeage % (1000 * 60 * 60)) / (1000 * 60));

        var bookingremainingtime = 30 - bookingtimeageinminutes;

        if(ticketdata.payment === "unpaid" && bookingremainingtime > 1 && hasunpaidticket === false){
            setHasunpaidticket(true);
        }
        
        if(ticketdata.payment === "unpaid" && bookingremainingtime > 1){
            
            return(
                <div key={idx}>
                    <div className="md:flex flex-wrap w-full border bg-white shadow-sm mb-6 rounded-xl overflow-hidden p-4 items-center justify-end">
                        
                        <div className="w-auto justify-self-start grow">
                            <h1 className="uppercase font-bold text-xl mb-2">{ticketdata.eventname}</h1>
                            <p className="mb-4 md:mb-0 uppercase flex text-xs"><CalendarIcon className="w-3 h-3 mr-1"/><span className="mr-4">{formatedeventdate}</span><LocationMarkerIcon className="w-3 h-3 mr-1 hidden md:inline-flex"/><span className="mr-4 hidden md:inline-flex">{ticketdata.eventlocation}</span><ClockIcon className="w-3 h-3 mr-1"/><span>REMAINING {bookingremainingtime} MINUTES</span></p>
                        </div>
                        <div className="w-auto md:flex flex-wrap">
                            <div className="w-auto md:mr-4 mb-4 md:mb-0 text-sky-500 font-bold text-lg uppercase md:text-right flex items-center md:block">
                                BDT {ticketdata.payable}
                                <p className="font-normal text-xs ml-2 md:ml-0">FOR {ticketdata.ticketcount} PERSON{ticketdata.ticketcount > 1 && "S"}</p>
                            </div>
                            <button onClick={handlePayNow.bind(this, thisticketid, ticketdata)} className="w-full md:w-auto bg-sky-500 hover:bg-sky-600 w-full transition block text-center text-white font-bold text-md rounded-lg px-4 py-3">PAY NOW</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
        
    });
    
    return (
        <Layout title="Premier Event Tickets">
            {isapploading ?
                <><Loading /><Loading /></>
            :   <div className="md:mt-12">
                    {hasunpaidticket &&
                    <div className="mb-12 md:mb-20">
                        <div className="md:flex items-center justify-center md:border-t-[1px] border-t-red-400 w-full relative mb-4 md:mb-12">
                            <div className="-mt-1 bg-gray-50 text-red-400 md:px-5 md:absolute font-black text-xl md:text-3xl">YOUR UNPAID TICKETS</div>
                        </div>
                        <div>
                            {renderUnpaidTickets}
                        </div>
                    </div>
                    }
                    
                    <div>
                        <div className="md:flex items-center justify-center md:border-t-[1px] border-t-sky-500 w-full relative mb-4 md:mb-12">
                            <div className="md:-mt-1 bg-gray-50 text-sky-500 md:px-5 md:absolute font-black text-xl md:text-3xl">YOUR TICKETS</div>
                        </div>
                        <div>
                            {renderTickets}
                        </div>
                    </div>
                 </div>
            }
            
        </Layout>
    );
}

export default Tickets;