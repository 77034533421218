import React, { useContext } from "react";
import { AuthContext } from "./service/auth";
import { useNavigate, Link } from "react-router-dom";

import Layout from "./components/Layout"

import heroBackground from "./assets/hero-bg.jpg";

import Landing from "./components/Landing";

function Home() {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    if(currentUser){
        var userFname = currentUser.displayName.split(' ').shift();
    }

    return (
        <Layout title="Premier Professionals Club">
            {currentUser ?
            <div className="bg-white bg-cover bg-center rounded-xl shadow-lg px-6 py-12 md:px-12 md:py-28 backdrop backdrop-blur-3xl" style={{ backgroundImage: `url(${heroBackground})` }}>
                <div className="text-center">
                    <div className="text-5xl font-bold text-gray-800 leading-none">Hello, {userFname}!</div>
                    <div className="mt-6 text-xl font-light text-true-gray-500 antialiased">Check our events page to join our upcoming event.</div>
                </div>
                <div className="text-center mt-20">
                    <div className="flex items-center justify-center w-auto h-12 rounded-full bg-cool-gray-100 text-gray-800 animate-bounce hover:text-gray-900 hover:bg-cool-gray-50 transition duration-300 ease-in-out cursor-pointer">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                        </svg>
                    </div>
                    <div className="mt-4">
                        <Link to="/events/" className="font-bold mt-6 px-8 py-4 rounded-full tracking-wide bg-sky-400 text-white outline-none focus:outline-none hover:shadow-lg hover:bg-sky-600 transition duration-200 ease-in-out">PREMIER EVENTS</Link>
                    </div>
                </div>
            </div>
            :
            <Landing/>
            }
            

        </Layout>
    );
}

export default Home;