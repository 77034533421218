import React, { useState, useContext, useEffect } from "react";
import { db } from "./service/firebase";
import { useNavigate, Link} from "react-router-dom";
import { collection, query, limit, onSnapshot, orderBy } from "firebase/firestore";

import { LocationMarkerIcon, CalendarIcon } from '@heroicons/react/outline'

import Layout from "./components/Layout"
import Loading from './components/Loading';


import headerBackground from "./assets/header-bg.jpg";

function Events() {
    const [isapploading, setIsapploading] = useState(true);

    const [eventslist, setEventslist] = useState([]);

    const navigate = useNavigate();

    useEffect(() => { 
        const geteventslist = onSnapshot(query(collection(db, "events"), limit(5), orderBy("createdAt", "desc")), snap => {
          const data = snap.docs.map(doc => doc)
          setEventslist(data);
          setIsapploading(false);
        });
 
        return () => geteventslist()
        
    }, []);

    const renderEvents = eventslist.map(function(data, idx) {
        const eventdata = data.data();
        const eventdate = new Date(eventdata.eventdate); 
        const formatedeventdate = eventdate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        
        return(
            <div key={idx}>
                <div className="block md:flex flex-wrap bg-white border shadow-sm mb-6 rounded-xl overflow-hidden p-4 items-center justify-center">
                    <div className="w-auto bg-200 md:w-1/6 overflow-hidden">
                        <div className="p-4 w-full h-full border text-center rounded-lg">
                            <div className="text-sky-500 font-bold text-xs uppercase py-2 md:py-0">{formatedeventdate}</div>
                        </div>
                    </div>
                    <div className="w-full md:w-4/6 md:pl-4 mb-4 md:mb-0">
                        <h1 className="uppercase font-bold text-lg mb-1">{eventdata.eventname}</h1>
                        <p className="uppercase flex text-xs"><LocationMarkerIcon className="w-3 h-3 mr-1"/><span>{eventdata.eventlocation}</span></p>
                    </div>
                    <div className="w-full md:w-1/6 md:pl-4">
                        <Link className="bg-sky-500 hover:bg-sky-600 transition block text-center text-white font-bold text-md rounded-lg p-3" to={`/event/${eventdata.eventslug}/`}>DETAILS</Link>
                    </div>
                </div>
            </div>
        );
    });
    
    return (
        <Layout title="Premier Events">
            <div className="rounded-xl shadow-lg bg-white flex flex-wrap overflow-hidden mb-8">
                <div className="w-full bg-200 bgmoveanimation rounded-xl overflow-hidden" style={{ backgroundImage: `url(${headerBackground})` }}>
                    <div className="px-4 py-8 md:p-24 w-full rounded-xl h-full bg-sky-500 bg-opacity-10 items-center text-center backdrop-blur-3xl text-white uppercase">
                        <h1 className="font-bold text-4xl mb-2">PREMIER EVENTS</h1>
                    </div>
                </div>
            </div>
            <div className="">
                {isapploading &&
                    <><Loading /><Loading /></>
                }
                {renderEvents}
            </div>
            
        </Layout>
    );
}

export default Events;