import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Layout from "./components/Layout"
import paymentmethods from "./assets/payments.png"

function PayNow() {
    const location = useLocation();
    const ticketdata = location.state.passbookingdata;
    const isnaked = location.state.isnaked;
    const [isagreedtoterms, setIsagreedtoterms] = useState(false);
    const [paynowbuttontext, setPaynowbuttontext] = useState("PAY NOW!");

    const handleSubmit = (e) => {
        setPaynowbuttontext("PLEASE WAIT...");
    }

    return (
        <Layout title="Pay For Your Ticket" isnaked={isnaked}>
            <div className="w-full max-w-lg mx-auto my-12 bg-white shadow-md border rounded-xl text-center overflow-hidden">
                <div className="px-6 pt-16 pb-20">
                    <p className="uppercase text-xs mb-12">You are booking <strong>{ticketdata.ticketcount} Seats</strong> in <strong>{ticketdata.eventname}</strong> Event</p>
                    <p className="font-bold mb-2 text-sky-700">YOU ARE PAYING</p>
                    <h2 className="font-bold text-sky-500 text-6xl">৳{ticketdata.payable.toLocaleString()}</h2>
                    <div className="mt-4 text-xs text-center text-gray-500">
                        <strong className="block mb-2">A TRANSACTION FEE WILL BE ADDED AT CHECKOUT.</strong>
                        AMERICAN EXPRESS CARDS: 3.5%<br/>
                        OTHER CARDS & MFS: 2.5%
                    </div>
                </div>
                <form method="POST" action="https://alohomora.premierprofessionals.club/shurjopay/sp.php" onSubmit={handleSubmit}>
                    <input type="hidden" name="customer_name" defaultValue={ticketdata.membername}/>
                    <input type="hidden" name="customer_mobile" defaultValue={ticketdata.memberphone}/>
                    <input type="hidden" name="customer_email" defaultValue={ticketdata.memberemail}/>
                    <input type="hidden" name="address" defaultValue="Dhaka"/>
                    <input type="hidden" name="amount" defaultValue={ticketdata.payable}/>
                    <input type="hidden" name="eventname" defaultValue={ticketdata.eventname}/>
                    <input type="hidden" name="ticketcount" defaultValue={ticketdata.ticketcount}/>
                    <input type="hidden" name="ticketid" defaultValue={ticketdata.ticketid}/>
                    <div className="form-check mb-6">
                        <input type="checkbox" value="true" id="flexCheckChecked" className="mr-2" onChange={(e) => setIsagreedtoterms(e.target.value)}/>
                        <label className="form-check-label inline-block text-gray-800" for="flexCheckChecked">
                            I have read and understood the <Link to="/terms/" className="underline">terms and conditions</Link>.
                        </label>
                    </div>
                    {isagreedtoterms ? 
                        <button className="bg-sky-500 hover:bg-sky-600 transition w-full text-center font-bold text-2xl text-white p-4" type="submit">{paynowbuttontext}</button>
                    :   
                        <button className="bg-sky-200 transition w-full text-center font-bold text-2xl text-white p-4" type="submit" disabled>{paynowbuttontext}</button>
                    }
                    
                </form>
            </div>
            <div className="max-w-lg mx-auto mb-10 px-6 py-4 border bg-white rounded-xl">
                <img className="w-full" src={paymentmethods} alt="Premier Club Payment Methods"/>
            </div>
        </Layout>
    );
}

export default PayNow;