import React, { useState, useContext, useEffect, useRef } from "react";
import { auth, db } from "./service/firebase";
import { AuthContext } from "./service/auth";
import { useNavigate, Link } from "react-router-dom";
import { setDoc, getDoc, doc, collection } from "firebase/firestore";
import BundledEditor from './components/BundledEditor'


import Layout from "./components/Layout"

import heroBackground from "./assets/hero-bg.jpg";


function CreateEvent() {

    const editorRef = useRef(null);

    const { currentUser } = useContext(AuthContext);
    const [useradmin, setUseradmin] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {

            const docRef = doc(db, "admins", currentUser.uid);

            getDoc(docRef)
            .then((snap) => {
                if (!snap.exists()) throw new Error(""); 
                if(snap.data().role === "admin"){
                    setUseradmin(true);
                }
                
            });
        }
        
    }, [currentUser]);

    const [eventname, setEventname] = useState("");
    const [eventlocation, setEventlocation] = useState("");
    const [eventdate, setEventdate] = useState("");
    const [eventregclosedate, setEventregclosedate] = useState("");
    const [eventcapacity, setEventcapacity] = useState("");
    const [evententryfee, setEvententryfee] = useState("");
    const [eventkidsentryfee, setEventkidsentryfee] = useState("");
    const [eventpickup, setEventpickup] = useState("yes");
    const [eventtshirt, setEventtshirt] = useState("yes");
    const [multiregister, setMultiregister] = useState("no");

    const [formbuttontext, setFormbuttontext] = useState("CREATE EVENT");

    function convertToSlug(Text)
    {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormbuttontext("PLEASE WAIT...");
        var eventurl = convertToSlug(eventname + "-" + Date.now());
        const newEventdata = doc(collection(db, "events"));
          var data = {
            createdAt: Date.now(),
            eventslug: eventurl,
            eventname : eventname,
            eventlocation : eventlocation,
            eventdate : eventdate,
            eventregclosedate : eventregclosedate,
            eventcapacity : eventcapacity,
            evententryfee : evententryfee,
            eventkidsentryfee : eventkidsentryfee,
            eventdetails : editorRef.current.getContent(),
            eventpickup : eventpickup,
            eventtshirt : eventtshirt,
            multiregister : multiregister
          };
          setDoc(newEventdata, data).then(() => {
            navigate("/event/"+eventurl+"/");
          }).catch((error) => {
            setFormbuttontext("CREATE EVENT");
          });
    }

    const inputclass = "block w-full px-6 py-4 mb-6 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none";
    const formlabelclass = "text-xs font-bold mb-2 block uppercase text-gray-700";
    
    
    return (
        <Layout title="Create New Event">
            {useradmin &&
            <div className="block bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="w-full bg-cover bg-center" style={{ backgroundImage: `url(${heroBackground})` }}>
                    <div className="px-4 py-24 w-full h-full bg-sky-500 rounded-t-xl bg-opacity-10 items-center text-center justify-center flex ">
                        <h1 className="text-sky-500 font-bold text-3xl tracking-widest">CREATE AN EVENT</h1>
                    </div>
                </div>
                <div className="w-full px-6 py-8 md:px-12 md:py-16">
                    <form className="eventCreateFrom flex flex-wrap" onSubmit={handleSubmit}>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Name</label>
                            <input required type="text" name="eventname" className={inputclass} placeholder="Name" value={eventname} onChange={(e) => setEventname(e.target.value)}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Location</label>
                            <input required type="text" name="eventlocation" className={inputclass} placeholder="Location" value={eventlocation} onChange={(e) => setEventlocation(e.target.value)}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Event Date</label>
                            <input required type="date" name="eventdate" className={inputclass} placeholder="Date" value={eventdate} onChange={(e) => setEventdate(e.target.value)}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Registration Close Date</label>
                            <input required type="date" name="eventregclosedate" className={inputclass} placeholder="Date" value={eventregclosedate} onChange={(e) => setEventregclosedate(e.target.value)}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Capacity</label>
                            <input required type="number" name="eventcapacity" className={inputclass} placeholder="Capacity" value={eventcapacity} onChange={(e) => setEventcapacity(e.target.value)}/>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Entry Fee (BDT)</label>
                            <input required type="number" name="evententryfee" className={inputclass} placeholder="Entry Fee" value={evententryfee} onChange={(e) => setEvententryfee(e.target.value)}/>
                        </div>
                        
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Entry Fee For Kids (BDT)</label>
                            <input required type="number" name="eventkidsentryfee" className={inputclass} placeholder="Entry Fee" value={eventkidsentryfee} onChange={(e) => setEventkidsentryfee(e.target.value)}/>
                        </div>
                        <div className="w-full px-3">
                            <label className={formlabelclass}>Details</label>
                            <div className="mb-6" data-color-mode="light">
                            <BundledEditor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue='<p>This is the initial content of the editor.</p>'
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                                'searchreplace', 'table', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                            </div>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Need Pickup Location?</label>
                            <select required type="number" name="eventpickup" className={inputclass} value={eventpickup} onChange={(e) => setEventpickup(e.target.value)}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Need T-Shirt Size?</label>
                            <select required name="eventtshirt" className={inputclass} value={eventtshirt} onChange={(e) => setEventtshirt(e.target.value)}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                            <label className={formlabelclass}>Allow Multiple Register?</label>
                            <select required name="multiregister" className={inputclass} value={multiregister} onChange={(e) => setMultiregister(e.target.value)}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div className="w-full px-3">
                            <button type="submit" className="bg-sky-500 p-4 block w-full rounded-lg text-white font-bold">{formbuttontext}</button>
                        </div>
                    </form>
                </div>
            </div>
            }
        </Layout>
    );
}

export default CreateEvent;