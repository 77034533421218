import React, { useState, useContext, useEffect } from "react";
import { auth, db } from "./service/firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { collection, setDoc, doc } from "firebase/firestore";
import { AuthContext } from "./service/auth";

import Layout from "./components/Layout"

import headerBackground from "./assets/header-bg.jpg";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if(currentUser){
      navigate("/");
    }
  }, []);

  const [issignedup, setIssignedup] = useState(false);

  useEffect(() => {
    if(issignedup){
      navigate("/");
    }
  }, [issignedup]);
  

  const [formerrorstate, setFormerrorstate] = useState(false);
  const [formbuttontext, setFormButtontext] = useState("CREATE ACCOUNT");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormerrorstate(false);
    setFormButtontext("PLEASE WAIT...");
    function onRegister() {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const newUserData = doc(collection(db, "users"), userCredential.user.uid);
          var data = {
            verified: false
          };
          setDoc(newUserData, data);


          updateProfile(userCredential.user, {
            displayName: name
          }).then(() => {
            setIssignedup(true);
          }).catch((error) => {
            
          });

        })
        
        .catch(function(error) {
          setFormerrorstate(true);
          setFormButtontext("CREATE ACCOUNT");
        });
    }
    onRegister();
  };

  const inputclass = "block w-full px-6 py-4 mb-4 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none";

  return (

    <Layout title="Register">
      <div className="block md:flex bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="w-full bg-200 md:w-3/6 bgmoveanimation" style={{ backgroundImage: `url(${headerBackground})` }}>
          <div className="px-4 py-12 md:p-2 w-full h-full bg-sky-500 rounded-t-xl md:rounded-l-xl md:rounded-t-none bg-opacity-10 items-center text-center justify-center flex backdrop-blur-3xl">
            <h1 className="text-white font-bold text-3xl tracking-widest">CREATE ACCOUNT</h1>
          </div>
        </div>
        <div className="w-full md:w-3/6 px-6 py-8 md:px-12 md:py-16">
        <div className={`text-red-500 font-bold text-xs bg-red-300 text-center p-4 rounded-lg border border-red-500 mb-4 ${formerrorstate ? "block" : "hidden"}`}>MAYBE YOU ALREADY HAVE AN ACCOUNT!<br></br>OR YOU USED A LOW SEQURITY PASSWORD.</div>
        <form className="signupForm" onSubmit={handleSubmit}>
            <input
              placeholder="Name"
              className={inputclass}
              onChange={(e) => setName(e.target.value)}
              required
            ></input>
            <input
              placeholder="Email"
              className={inputclass}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            ></input>
            <input
              placeholder="Password"
              className={inputclass}
              onChange={(e) => setPassword(e.target.value)}
              required
              type="password"
            ></input>
            <div className="block">
                <button className="w-full px-5 py-4 font-bold text-white bg-sky-500 rounded-lg uppercase border-2 border-sky-500 hover:bg-sky-600 hover:border-sky-600 transition">{formbuttontext}</button>
            </div>
            <div className="flex items-center justify-center border-t-[1px] border-t-slate-300 w-full relative mt-16">
              <div className="-mt-1 font-bod bg-white px-5 absolute font-bold text-xs">HAVE AN ACCOUNT?</div>
            </div>
            <div className="block mt-8">
                <Link to="/login/" className="w-full block text-center border-2 px-5 py-4 font-bold text-sky-500 border-sky-500 rounded-lg uppercase hover:bg-sky-500 hover:text-white transition">CLICK HERE TO LOGIN</Link>
            </div>
          </form>
        </div>
      </div>
      
    </Layout>
  );
};

export default SignUp;