import React, { useState, useContext, useEffect } from "react";
import { auth, db } from "./service/firebase";
import { AuthContext } from "./service/auth";
import { useNavigate, Link } from "react-router-dom";
import { collection, query, limit, onSnapshot, orderBy, doc, getDoc, where } from "firebase/firestore";

import Layout from "./components/Layout"
import Loading from './components/Loading';

import heroBackground from "./assets/hero-bg.jpg";


function Participants() {
    const [isapploading, setIsapploading] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [useradmin, setUseradmin] = useState(false);
    const [seerawtable, setSeerawtable] = useState(false);
    const [eventslist, setEventslist] = useState([]);
    const [participantslist, setParticipantslist] = useState([]);
    const [selectevent, setSelectevent] = useState("selectevent");

    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {

            const docRef = doc(db, "admins", currentUser.uid);

            getDoc(docRef)
            .then((snap) => {
                if (!snap.exists()) throw new Error(""); 
                if(snap.data().role === "admin"){
                    setUseradmin(true);
                }
                
            });
        }
        
    }, [currentUser]);

    useEffect(() => { 
        const geteventslist = onSnapshot(query(collection(db, "events"), limit(5), orderBy("createdAt", "desc")), snap => {
          const data = snap.docs.map(doc => doc)
          setEventslist(data);
        });
 
        return () => geteventslist()
        
    }, []);

    useEffect(() => {
        if (selectevent !== "selectevent") {
            setIsapploading(true);
            const getParticipantlist = onSnapshot(query(collection(db, "tickets"), limit(500), where("eventid", "==", selectevent), orderBy("createdAt", "desc")), snap => {
                const data = snap.docs.map(doc => doc)
                setParticipantslist(data);
                setIsapploading(false);
            });
    
            return () => getParticipantlist()
        }
        
    }, [selectevent]);

    const renderEvents = eventslist.map(function(data, idx) {
        const eventid = data.id;
        const eventdata = data.data();
        
        return(
            <option key={idx} value={eventid}>{eventdata.eventname}</option>
        );
    });
    var totaltickets = 0;
    var totalpaidltickets = 0;
    var totalunpaidltickets = 0;
    const renderParticipants = participantslist.map(function(data, idx) {
        const ticketid = data.id;
        const ticketdata = data.data();
        totaltickets += parseInt(ticketdata.ticketcount);
        if(ticketdata.payment === "paid"){
            totalpaidltickets += parseInt(ticketdata.ticketcount);
            return(
                <div key={idx} className="border-2 border-sky-500 mb-4 rounded-lg px-3 py-2">
                    <div className="flex flex-wrap w-full">
                        <div className="grow">{ticketdata.membername} <span className="bg-sky-500 text-xs text-white px-2 py-1 rounded-md ml-2">{ticketdata.memberphone}</span></div>
                        <div className="self-justify-end font-bold text-sm">{ticketdata.ticketcount} PERSON</div>
                    </div>
                </div>
            );
        } else {
            totalunpaidltickets += parseInt(ticketdata.ticketcount);
        }
        
    });

    const renderUnpaidParticipants = participantslist.map(function(data, idx) {
        const ticketid = data.id;
        const ticketdata = data.data();
        if(ticketdata.payment !== "paid"){
            return(
                <div key={idx} className="border-2 border-red-400 mb-4 rounded-lg px-3 py-2">
                    <div className="flex flex-wrap w-full">
                        <div className="grow">{ticketdata.membername} <span className="bg-sky-500 text-xs text-white px-2 py-1 rounded-md ml-2">{ticketdata.memberphone}</span></div>
                        <div className="self-justify-end font-bold text-sm">{ticketdata.ticketcount} PERSON</div>
                    </div>
                </div>
            );
        }
        
    });

    const handleSeeRaw = (e) => {
        setSeerawtable(true);
    }

    const handleSeeRawHide = (e) => {
        setSeerawtable(false);
    }


    const renderRawParticipants = participantslist.map(function(data, idx) {
        const ticketdata = data.data();
        if(ticketdata.payment === "paid"){
            return (
              <tr className="whitespace-nowrap">
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.membername}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.memberemail}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.memberphone}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.pickuplocation}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.payment}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.payable}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.paymenttxr}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.ticketcount}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.ticketcountkids}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtkids}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtsize}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtname}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtsize2}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtname2}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtsize3}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtname3}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtsize4}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.tshirtname4}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 border-b">
                  {ticketdata.bookingnote}
                </td>
              </tr>
            );
        }
        
    });

    function renderRawParticipantsheader() {
        return (
          <tr>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">Name</th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">Email</th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">Phone</th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Pickup
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Status
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Ammount
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">TXR</th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Ticket Count
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">Kids</th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Kids T-Shirt Details
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 1 Size
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 1 Name
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 2 Size
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 2 Name
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 3 Size
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 3 Name
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 4 Size
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              T-shirt 4 Name
            </th>
            <th className="px-6 py-3 text-xs text-gray-500 uppercase">
              Note
            </th>
          </tr>
        );
    }

    
    return (
        <>
            {seerawtable ?
                <div className="relative pt-11">
                    <div className="flex p-2 bg-sky-300 w-screen h-11 items-center justify-end fixed top-0 left-0">
                        <button onClick={handleSeeRawHide}>CLOSE</button>
                    </div>
                    <div className="text-left">
                        <table>
                            <thead className="bg-gray-50">
                                {renderRawParticipantsheader()}
                            </thead>
                            <tbody className="bg-white">
                                {renderRawParticipants}
                            </tbody>
                        </table>
                    </div>
                </div>
            :
            <Layout title="Participants">
                {useradmin &&
                <div className="block bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="w-full bg-cover bg-center" style={{ backgroundImage: `url(${heroBackground})` }}>
                        <div className="block px-4 py-24 w-full h-full bg-sky-500 rounded-t-xl bg-opacity-10 text-center">
                            <h1 className="block w-full text-sky-500 font-bold text-3xl tracking-widest mb-6">PARTICIPANTS</h1>
                            <div className="block w-full">
                                <form>
                                    <select required type="number" name="selectevent" className="block mx-auto w-auto px-6 py-4 mb-6 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none text-center" value={selectevent} onChange={(e) => setSelectevent(e.target.value)}>
                                        <option value="selectevent">Select Event</option>
                                        {renderEvents}
                                    </select>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-6 py-8 md:px-12 md:py-16">
                        {isapploading ?
                            <><Loading /><Loading /></>
                        :
                        <div className="mb-16 bg-sky-500 w-full p-8 text-center font-bold text-xl rounded-xl text-white">
                            <p>TOTAL TICKETS SOLD: {totaltickets} ({totalpaidltickets} PAID)</p>
                            <p>
                                <button onClick={handleSeeRaw} className="mt-6 text-xs border border-white px-4 py-2 rounded-md hover:bg-white transition hover:text-sky-500">SEE RAW DATA</button>
                            </p>
                        </div>
                        }
                        
                        {totalunpaidltickets >= 1 &&
                            <div className="mb-16">
                                <div className="md:flex items-center justify-center md:border-t-[1px] border-t-sky-500 w-full relative mb-4 md:mb-12">
                                    <div className="md:-mt-1 bg-gray-50 text-sky-500 md:px-5 md:absolute font-black text-xl md:text-3xl">UNPAID PARTICIPANTS</div>
                                </div>
                                {renderUnpaidParticipants}
                            </div>
                        }
                        
                        {totalpaidltickets >= 1 &&
                            <div>
                                <div className="md:flex items-center justify-center md:border-t-[1px] border-t-sky-500 w-full relative mb-4 md:mb-12">
                                    <div className="md:-mt-1 bg-gray-50 text-sky-500 md:px-5 md:absolute font-black text-xl md:text-3xl">PAID PARTICIPANTS</div>
                                </div>
                                {renderParticipants}
                            </div>
                        }
                        
                    </div>
                </div>
                }
            </Layout>
            }
        </>
    );
}

export default Participants;