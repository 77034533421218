import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import SignUp from "./Signup";
import PasswordReset from "./password-reset";
import Events from "./Events";
import Tickets from "./Tickets";
import CreateEvent from "./CreateEvent";
import BookNow from "./BookNow";
import EventDetails from "./EventDetails";
import PayNow from "./PayNow";
import NotFound from "./404";
import MessagePage from "./MessagePage";
import Profile from "./Profile";
import Participants from "./Participants";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import AboutUs from "./About";
import ContactUs from "./Contact";
import Terms from "./Terms";
import DownloadTicket from "./DownloadTicket";
import ScanTicket from "./ScanTicket";
import { AuthProvider } from "./service/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./service/firebase";

import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, [currentUser]);

  return (
    <AuthProvider>
      <div className="app">
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login/" element={<Login />} />
            <Route exact path="/signup/" element={<SignUp />} />
            <Route exact path="/password-reset/" element={<PasswordReset />} />
            <Route exact path="/events/" element={<Events />} />
            <Route exact path="/tickets/" element={<Tickets />} />
            <Route exact path="/update-profile/" element={<Profile />} />
            <Route exact path="/create-event/" element={<CreateEvent />} />
            <Route exact path="/participants/" element={<Participants />} />
            <Route exact path="/scan-ticket/" element={<ScanTicket />} />
            <Route exact path="/book-now/" element={<BookNow />} />
            <Route exact path="/pay-now/" element={<PayNow />} />
            <Route exact path="/privacy-policy/" element={<PrivacyPolicy />} />
            <Route exact path="/refund-policy/" element={<RefundPolicy />} />
            <Route exact path="/terms/" element={<Terms />} />
            <Route exact path="/about/" element={<AboutUs />} />
            <Route exact path="/contact/" element={<ContactUs />} />
            <Route exact path="/download-ticket/" element={<DownloadTicket />} />
            <Route exact path="/payment-success/" element={<MessagePage type="paymentsuccess" />} />
            <Route exact path="/payment-failed/" element={<MessagePage type="paymentfailed" />} />
            <Route path="event/:slug/" element={<EventDetails />} />
            <Route path="event/:slug/booknow/" element={<EventDetails triggerbooknow={true} />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;