import React, { useState, useContext, useEffect } from "react";
import { db } from "./service/firebase";
import { AuthContext } from "./service/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  getDoc,
  doc,
  setDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import Layout from "./components/Layout";
import Loading from "./components/Loading";

import headerBackground from "./assets/header-bg.jpg";

function BookNow() {
  const [isapploading, setIsapploading] = useState(true);
  const location = useLocation();
  const eventid = location.state.eventid;
  const eventtotalseat = location.state.eventtotalseat;
  const eventdata = location.state.eventdata;
  const isnaked = location.state.isnaked;
  const [userdata, setUserdata] = useState("");
  const [thiseventavailableseat, setThiseventavailableseat] = useState(0);
  const [thisbookingdatatoarray, setThisbookingdatatoarray] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (eventid) {
      const getticketlist = onSnapshot(
        query(collection(db, "tickets"), where("eventid", "==", eventid)),
        (snap) => {
          var thiseventticketcounter = 0;
          snap.docs.map(function (doc, idx) {
            thiseventticketcounter += Number(doc.data().ticketcount);
          });
          var thiseventticketavailable =
            eventtotalseat - thiseventticketcounter;
          setThiseventavailableseat(thiseventticketavailable);
          setIsapploading(false);
        }
      );
    }
  }, [eventid]);

  const [formbuttontext, setFormbuttontext] = useState("BOOK YOUR SEAT");

  const [membername, setMembername] = useState("");
  const [memberphone, setMemberphone] = useState("");
  const [memberemail, setMemberemail] = useState("");
  const [ticketcount, setTicketcount] = useState(1);
  const [ticketcountkids, setTicketcountkids] = useState(0);
  const [bookingnote, setBookingnote] = useState("");
  const [pickuplocation, setPickuplocation] = useState("");
  const [tshirtsize, setTshirtsize] = useState("xl");
  const [tshirtname, setTshirtname] = useState("");
  const [tshirtsize2, setTshirtsize2] = useState("xl");
  const [tshirtname2, setTshirtname2] = useState("");
  const [tshirtsize3, setTshirtsize3] = useState("xl");
  const [tshirtname3, setTshirtname3] = useState("");
  const [tshirtsize4, setTshirtsize4] = useState("xl");
  const [tshirtname4, setTshirtname4] = useState("");
  const [tshirtkids, setTshirtkids] = useState("");

  useEffect(() => {
    if (currentUser) {
      setMembername(currentUser.displayName);
      setMemberemail(currentUser.email);
      const userDocRef = doc(db, "users", currentUser.uid);

      getDoc(userDocRef).then((snap) => {
        if (!snap.exists()) throw new Error("");
        if (snap.data().phone) {
          setMemberphone(snap.data().phone);
        }
      });
    }
  }, [currentUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormbuttontext("PLEASE WAIT...");
    const newTicket = doc(collection(db, "tickets"));
    var bookingdata = {
      membername: membername,
      memberphone: memberphone,
      memberemail: memberemail,
      ticketcount: ticketcount,
      ticketcountkids: ticketcountkids,
      bookingnote: bookingnote,
      pickuplocation: pickuplocation,
      tshirtsize: tshirtsize,
      tshirtname: tshirtname,
      tshirtsize2: tshirtsize2,
      tshirtname2: tshirtname2,
      tshirtsize3: tshirtsize3,
      tshirtname3: tshirtname3,
      tshirtsize4: tshirtsize4,
      tshirtname4: tshirtname4,
      tshirtkids: tshirtkids,
      useruid: currentUser.uid,
      eventid: eventid,
      eventname: eventdata.eventname,
      eventlocation: eventdata.eventlocation,
      eventdate: eventdata.eventdate,
      payment: "unpaid",
      paymenttxr: "",
      payable: totalpayable(),
      createdAt: Date.now(),
    };

    setDoc(newTicket, bookingdata)
      .then(() => {
        setFormbuttontext("REDIRECTING TO PAYMENT PAGE...");
        var passbookingdata = {
          membername: bookingdata.membername,
          memberemail: memberemail,
          memberphone: bookingdata.memberphone,
          ticketcount: bookingdata.ticketcount,
          useruid: bookingdata.useruid,
          eventid: bookingdata.eventid,
          eventname: bookingdata.eventname,
          payable: bookingdata.payable,
          ticketid: newTicket.id,
        };
        navigate("/pay-now/", {
          state: { passbookingdata: passbookingdata, isnaked: isnaked },
        });
      })
      .catch((error) => {
        setFormbuttontext("BOOK YOUR SEAT");
      });
  };

  const inputclass =
    "block w-full px-6 py-4 mb-6 border border-gray-200 rounded-lg focus:ring focus:ring-sky-500 focus:outline-none appearance-none";
  const formlabelclass = "text-xs font-bold mb-2 block uppercase text-gray-700";

  function totalpayable() {
    var totalpayableamount;
    totalpayableamount = ticketcount * eventdata.evententryfee;
    if (eventdata.eventkidsentryfee) {
      var totalpayableamountforkids =
        ticketcountkids * eventdata.eventkidsentryfee;
      totalpayableamount = totalpayableamount + totalpayableamountforkids;
    }
    return parseFloat(totalpayableamount).toFixed(2);
  }

  return (
    <Layout title="Book Your Seat" isnaked={isnaked}>
      {isapploading && (
        <>
          <Loading />
          <Loading />
        </>
      )}
      {!isapploading && (
        <div>
          <div className="rounded-xl shadow-lg bg-white flex flex-wrap overflow-hidden">
            <div
              className="w-full bg-200 bgmoveanimation rounded-t-xl"
              style={{ backgroundImage: `url(${headerBackground})` }}
            >
              <div className="px-4 py-8 md:p-24 w-full h-full rounded-t-xl bg-sky-500 bg-opacity-10 items-center text-center backdrop-blur-3xl text-white uppercase">
                <p className="text-sm tracking-widest text-sky-500 mb-4">
                  BOOK YOUR SEAT
                </p>
                <h1
                  className={`font-bold mb-6 ${
                    isnaked ? "text-2xl" : "text-4xl"
                  }`}
                >
                  {eventdata.eventname}
                </h1>
                <p className="text-center">
                  <span className="relative border-2 border-sky-500 inline-block px-6 py-2 text-sky-500 rounded-full text-sm font-bold">
                    <span className="mr-2 inline-flex  relative">
                      <span className="animate-ping absolute text-sky-400 opacity-50">
                        {thiseventavailableseat}
                      </span>
                      {thiseventavailableseat}
                    </span>
                    SEATS AVAILABLE
                  </span>
                </p>
              </div>
            </div>
            <div className="w-full px-6 py-8 md:px-8 md:py-12">
              <form
                className="ticketCreateFrom flex flex-wrap w-full"
                onSubmit={handleSubmit}
              >
                <div className="w-full md:w-3/6 px-3">
                  <label className={formlabelclass}>Your Name</label>
                  <input
                    required
                    type="text"
                    name="membername"
                    className={`bg-gray-200 ${inputclass}`}
                    placeholder="Name"
                    value={membername}
                    onChange={(e) => setMembername(e.target.value)}
                    disabled
                  />
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <label className={formlabelclass}>Your Phone</label>
                  <input
                    required
                    type="text"
                    name="memberphone"
                    className={inputclass}
                    placeholder="Your Phone"
                    value={memberphone}
                    onChange={(e) => setMemberphone(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-3/6 px-3">
                  <label className={formlabelclass}>
                    How Many Seats? (BDT {eventdata.evententryfee} PER PERSON)
                  </label>
                  <select
                    required
                    type="number"
                    name="ticketcount"
                    className={inputclass}
                    value={ticketcount}
                    onChange={(e) => setTicketcount(e.target.value)}
                  >
                    <option value="1">One</option>
                    {eventdata.multiregister === "yes" && (
                      <>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                      </>
                    )}
                  </select>
                </div>
                {eventdata.eventkidsentryfee > 0 && (
                  <div className="w-full md:w-3/6 px-3">
                    <label className={formlabelclass}>
                      How Many Kids You Will Bring? (BDT{" "}
                      {eventdata.eventkidsentryfee} PER KID)
                    </label>
                    <select
                      required
                      type="number"
                      name="ticketcountkids"
                      className={inputclass}
                      value={ticketcountkids}
                      onChange={(e) => setTicketcountkids(e.target.value)}
                    >
                      <option value="0">None</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                    </select>
                  </div>
                )}
                {eventdata.eventtshirt === "yes" && ticketcountkids >= 1 && (
                  <div className="w-full px-3">
                    <label className={formlabelclass}>
                      KIDS T-SHIRT/JERSEY (PLEASE WRITE IN DETAILS INCLUDING
                      NAME AND SIZE)
                    </label>
                    <textarea
                      required
                      name="tshirtkids"
                      className={inputclass}
                      placeholder="KIDS T-Shirt Name and Size"
                      value={tshirtkids}
                      onChange={(e) => setTshirtkids(e.target.value)}
                    />
                  </div>
                )}
                {eventdata.eventpickup === "yes" && (
                  <div className="w-full flex flex-wrap">
                    <div className="w-full md:w-3/6 px-3">
                      <label className={formlabelclass}>Pickup Location</label>
                      <input
                        required
                        type="text"
                        name="pickuplocation"
                        className={inputclass}
                        placeholder="Pickup Location"
                        value={pickuplocation}
                        onChange={(e) => setPickuplocation(e.target.value)}
                      />
                    </div>
                    <div className="w-full md:w-3/6 px-3 text-xs italic items-center flex">
                      <span className="pb-2">
                        There is no guarantee that, we will pick you from here.
                        <br />
                        We will inform your exact pickup location few days
                        before the event.
                      </span>
                    </div>
                  </div>
                )}
                {eventdata.eventtshirt === "yes" && (
                  <>
                    <div className="flex flex-wrap w-full">
                      <div className="w-full md:w-3/6 px-3">
                        <label className={formlabelclass}>
                          T-Shirt/Jersey Size
                        </label>
                        <select
                          required
                          name="tshirtsize"
                          className={inputclass}
                          value={tshirtsize}
                          onChange={(e) => setTshirtsize(e.target.value)}
                        >
                          <option value="xs">XS</option>
                          <option value="s">S</option>
                          <option value="m">M</option>
                          <option value="l">L</option>
                          <option value="xl">XL</option>
                          <option value="xxl">XXL</option>
                          <option value="xxxl">XXXL</option>
                        </select>
                      </div>
                      <div className="w-full md:w-3/6 px-3">
                        <label className={formlabelclass}>Name on Jersey</label>
                        <input
                          type="text"
                          
                          name="tshirtname"
                          className={inputclass}
                          placeholder="Name on Jersey"
                          value={tshirtname}
                          onChange={(e) => setTshirtname(e.target.value)}
                        />
                      </div>
                    </div>
                    {ticketcount >= 2 && (
                      <div className="flex flex-wrap w-full">
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (2) T-Shirt/Jersey Size
                          </label>
                          <select
                            required
                            name="tshirtsize2"
                            className={inputclass}
                            value={tshirtsize2}
                            onChange={(e) => setTshirtsize2(e.target.value)}
                          >
                            <option value="xs">XS</option>
                            <option value="s">S</option>
                            <option value="m">M</option>
                            <option value="l">L</option>
                            <option value="xl">XL</option>
                            <option value="xxl">XXL</option>
                            <option value="xxxl">XXXL</option>
                          </select>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (2) Name on Jersey
                          </label>
                          <input
                            type="text"
                            required
                            name="tshirtname2"
                            className={inputclass}
                            placeholder="Name on Jersey"
                            value={tshirtname2}
                            onChange={(e) => setTshirtname2(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {ticketcount >= 3 && (
                      <div className="flex flex-wrap w-full">
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (3) T-Shirt/Jersey Size
                          </label>
                          <select
                            required
                            name="tshirtsize3"
                            className={inputclass}
                            value={tshirtsize3}
                            onChange={(e) => setTshirtsize3(e.target.value)}
                          >
                            <option value="xs">XS</option>
                            <option value="s">S</option>
                            <option value="m">M</option>
                            <option value="l">L</option>
                            <option value="xl">XL</option>
                            <option value="xxl">XXL</option>
                            <option value="xxxl">XXXL</option>
                          </select>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (3) Name on Jersey
                          </label>
                          <input
                            type="text"
                            required
                            name="tshirtname3"
                            className={inputclass}
                            placeholder="Name on Jersey"
                            value={tshirtname3}
                            onChange={(e) => setTshirtname3(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    {ticketcount >= 4 && (
                      <div className="flex flex-wrap w-full">
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (4) T-Shirt/Jersey Size
                          </label>
                          <select
                            required
                            name="tshirtsize4"
                            className={inputclass}
                            value={tshirtsize4}
                            onChange={(e) => setTshirtsize4(e.target.value)}
                          >
                            <option value="xs">XS</option>
                            <option value="s">S</option>
                            <option value="m">M</option>
                            <option value="l">L</option>
                            <option value="xl">XL</option>
                            <option value="xxl">XXL</option>
                            <option value="xxxl">XXXL</option>
                          </select>
                        </div>
                        <div className="w-full md:w-3/6 px-3">
                          <label className={formlabelclass}>
                            (4) Name on Jersey
                          </label>
                          <input
                            type="text"
                            required
                            name="tshirtname4"
                            className={inputclass}
                            placeholder="Name on Jersey"
                            value={tshirtname4}
                            onChange={(e) => setTshirtname4(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="w-full px-3">
                  <label className={formlabelclass}>Any Request For Us?</label>
                  <textarea
                    name="bookingnote"
                    className={inputclass}
                    placeholder="Any Request?"
                    value={bookingnote}
                    onChange={(e) => setBookingnote(e.target.value)}
                  />
                </div>
                <div className="w-full mb-6 mx-3 bg-sky-100 rounded-lg p-6 font-bold text-xl text-center text-sky-500">
                  TOTAL PAYABLE AMOUNT: BDT {totalpayable()} ONLY
                </div>
                {ticketcount <= thiseventavailableseat ? (
                  <div className="w-full px-3">
                    <button
                      type="submit"
                      className="bg-sky-500 p-4 block w-full rounded-lg text-white font-bold"
                    >
                      {formbuttontext}
                    </button>
                  </div>
                ) : (
                  <div className="w-full px-3">
                    <div className="bg-sky-200 p-4 block w-full rounded-lg text-white font-bold text-center">
                      HOUSEFULL
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BookNow;
